import { createContext } from "react";
import { User } from "./models/User";
import { Company } from "./models/Company";

export type ContextType = {
  user?: User;
  company?: Company;
};

export const Context = createContext<ContextType | null>(null);
