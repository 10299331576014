import { API } from "./API";
import { Mission } from "../models/Mission";
import { Page } from "./Page";

export const loadMission = (id: string): Promise<Mission> => {
  return API.get<Mission>(`mission/${id}`);
};

export const loadMissions = (): Promise<Mission[]> => {
  return API.get<Mission[]>("mission");
};

export const loadMissionsPage = (
  page?: number,
  size?: number,
  sort?: string,
  dir?: string,
  filter?: string
): Promise<Page<Mission>> => {
  return API.get<Page<Mission>>(
    "mission/page?" +
      API.buildQueryParams({
        page,
        size,
        sort,
        dir,
        filter,
      })
  );
};

export const updateMission = (mission: Mission): Promise<void> => {
  return API.put<void>(`mission/${mission.id}`, mission);
};

export const createMission = (mission: Mission): Promise<Mission> => {
  return API.post<Mission>("mission", mission);
};
