import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Mission } from "../models/Mission";
import { createMission, loadMission, updateMission } from "../api/Mission";
import { MediaFileGallery } from "../components/MediaFileGallery";
import { toast } from "react-toastify";
import { Breadcrumb } from "../components/Breadcrumb";
import { Context } from "../Context";

export const MissionDetails = () => {
  const [mission, setMission] = useState<Mission>();
  const [text, setText] = useState("");
  const [shortText, setShortText] = useState("");
  const [type, setType] = useState("MULTI_CHOICE");
  const [pointsCorrect, setPointsCorrect] = useState("0");
  const [pointsIncorrect, setPointsIncorrect] = useState("0");
  const [answersCorrect, setAnswersCorrect] = useState("");
  const [answersIncorrect, setAnswersIncorrect] = useState("");
  const [noPass, setNoPass] = useState("0");
  const [retry, setRetry] = useState("0");
  const [setVariable, setSetVariable] = useState("");
  const [mediaPath, setMediaPath] = useState("");
  const [mediaType, setMediaType] = useState("");
  const [timeLimit, setTimeLimit] = useState("");
  const [timeLimitScore, setTimeLimitScore] = useState("");
  const [category, setCategory] = useState("");
  const [actions, setActions] = useState("");
  const [siblings, setSiblings] = useState("");
  const [saving, setSaving] = useState(false);
  const [owned, setOwned] = useState(false);
  const { id } = useParams();

  const navigate = useNavigate();
  const context = useContext(Context);

  useEffect(() => {
    if (!context?.user) {
      return;
    }

    setOwned(
      context.user.company_id == mission?.company_id ||
        !!context.user.is_superadmin ||
        id === "new"
    );
  }, [context, mission, id]);

  useEffect(() => {
    if (!id) {
      return;
    }

    if (id !== "new") {
      loadMission(id).then((res) => {
        setMission(res);
        setText(res.text.replace(/\\n/g, "\n"));
        setShortText(res.short_text);
        setType(res.type);
        setPointsCorrect(res.points_correct.toString());
        setPointsIncorrect(res.points_incorrect.toString());
        setAnswersCorrect((res.answers_correct || "").replace(/\|/g, "\n"));
        setAnswersIncorrect((res.answers_incorrect || "").replace(/\|/g, "\n"));
        setNoPass(res.no_pass.toString());
        setRetry(res.retry.toString());
        setSetVariable(res.set_variable);
        setMediaPath(res.media_path);
        setMediaType(res.media_type);
        setTimeLimit(res.time_limit?.toString() || "");
        setTimeLimitScore(res.time_limit_score?.toString() || "");
        setCategory(res.category || "");
        setActions((res.actions || "").replace(/\|/g, "\n"));
        setSiblings(res.siblings || "");
      });
    }
  }, [id]);

  const save = () => {
    if (saving || !context?.user) {
      return;
    }

    setSaving(true);

    const data = {
      text,
      short_text: shortText,
      type,
      points_correct: parseInt(pointsCorrect, 10),
      points_incorrect: parseInt(pointsIncorrect, 10),
      answers_correct: answersCorrect.replace(/\n/g, "|"),
      answers_incorrect: answersIncorrect.replace(/\n/g, "|"),
      no_pass: noPass === "1",
      retry: retry === "1",
      set_variable: setVariable,
      media_path: mediaPath,
      media_type: mediaType,
      time_limit: parseInt(timeLimit || "0", 10),
      time_limit_score: parseInt(timeLimitScore || "0", 10),
      category,
      actions: actions.replace(/\n/g, "|"),
      siblings,
    };

    if (id === "new") {
      createMission({
        ...data,
        id: 0,
        company_id: context.user.company_id,
        is_public: false,
      })
        .then((res) => {
          toast.success("Mission was saved.");
          navigate(`/missions/${res.id}`);
        })
        .catch((error) => toast.error("Failed to create mission."))
        .finally(() => {
          setSaving(false);
        });
    } else {
      if (!mission) {
        return;
      }

      updateMission({ ...mission, ...data })
        .then(() => toast.success("Mission saved."))
        .catch((error) => toast.error("Failed to save mission."))
        .finally(() => {
          setSaving(false);
        });
    }
  };

  return (
    <>
      <Breadcrumb
        items={[
          { text: "Missions", link: "/missions" },
          { text: text.substr(0, 100) || "", link: `/missions/${mission?.id}` },
        ]}
      />
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="card">
            <div className="card-header pb-0">
              <h5 className="card-title mb-0">Settings</h5>
            </div>
            <div className="card-body">
              <div className="form-group">
                <label className="form-label">Text</label>
                {/*<input*/}
                {/*  type="text"*/}
                {/*  className="form-control"*/}
                {/*  value={text}*/}
                {/*  disabled={!owned}*/}
                {/*  onChange={(evt) => setText(evt.target.value)}*/}
                {/*/>*/}
                <textarea
                  rows={10}
                  className="w-100 form-control"
                  style={{ whiteSpace: "pre-wrap" }}
                  value={text}
                  disabled={!owned}
                  onChange={(evt) => setText(evt.target.value)}
                />
              </div>
              <div className="form-group mt-4">
                <label className="form-label">
                  Short Text (Used in gallery & follow)
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={shortText}
                  disabled={!owned}
                  onChange={(evt) => setShortText(evt.target.value)}
                />
              </div>
              <div className="form-group mt-4">
                <label className="form-label">Category</label>
                <input
                  type="text"
                  className="form-control"
                  value={category}
                  disabled={!owned}
                  onChange={(evt) => setCategory(evt.target.value)}
                />
              </div>
              <div className="form-group mt-4">
                <label className="form-label">Type</label>
                <select
                  className="form-select"
                  value={type}
                  disabled={!owned}
                  onChange={(evt) => setType(evt.target.value)}
                >
                  <option value="MULTI_CHOICE">MULTICHOICE</option>
                  <option value="FREE_TEXT">FREETEXT</option>
                  <option value="PHOTO">PHOTO</option>
                  <option value="NONE">NONE</option>
                </select>
              </div>
              <div className="form-group mt-4">
                <label className="form-label">Points correct</label>
                <input
                  type="number"
                  className="form-control"
                  min={0}
                  value={pointsCorrect}
                  disabled={!owned}
                  onChange={(evt) => setPointsCorrect(evt.target.value)}
                />
              </div>
              <div className="form-group mt-4">
                <label className="form-label">Points incorrect</label>
                <input
                  type="number"
                  className="form-control"
                  max={0}
                  value={pointsIncorrect}
                  disabled={!owned}
                  onChange={(evt) => setPointsIncorrect(evt.target.value)}
                />
              </div>
              <div className="form-group mt-4">
                <label className="form-label">Time limit (seconds)</label>
                <input
                  type="number"
                  className="form-control"
                  min={0}
                  value={timeLimit}
                  disabled={!owned}
                  onChange={(evt) => setTimeLimit(evt.target.value)}
                />
              </div>
              <div className="form-group mt-4">
                <label className="form-label">Time limit score</label>
                <input
                  type="number"
                  className="form-control"
                  max={0}
                  value={timeLimitScore}
                  disabled={!owned}
                  onChange={(evt) => setTimeLimitScore(evt.target.value)}
                />
              </div>
              <div className="form-group mt-4">
                <label className="form-label">Correct answer</label>
                <textarea
                  className="form-control"
                  rows={1}
                  value={answersCorrect}
                  disabled={!owned}
                  onChange={(evt) => setAnswersCorrect(evt.target.value)}
                />
              </div>
              <div className="form-group mt-4">
                <label className="form-label">Wrong answers</label>
                <textarea
                  className="form-control"
                  rows={6}
                  style={{ whiteSpace: "pre-wrap" }}
                  value={answersIncorrect}
                  disabled={!owned}
                  onChange={(evt) => setAnswersIncorrect(evt.target.value)}
                />
              </div>
              <hr />
              <h2>
                Advanced Options{" "}
                <span className="text-danger ms-5">
                  <i className="fa-solid fa-triangle-exclamation"></i> DANGEROUS{" "}
                  <i className="fa-solid fa-triangle-exclamation"></i>
                </span>
              </h2>
              <small className="text-muted">
                These options usually doesn't need to be changed.
              </small>
              <div className="form-group mt-4">
                <label className="form-label">Disable pass</label>
                <select
                  className="form-control"
                  value={noPass}
                  disabled={!owned}
                  onChange={(evt) => setNoPass(evt.target.value)}
                >
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
              <div className="form-group mt-4">
                <label className="form-label">Allow retry</label>
                <select
                  className="form-control"
                  value={retry}
                  disabled={!owned}
                  onChange={(evt) => setRetry(evt.target.value)}
                >
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
              <div className="form-group mt-4">
                <label className="form-label">Set variable</label>
                <input
                  type="text"
                  className="form-control"
                  value={setVariable}
                  disabled={!owned}
                  onChange={(evt) => setSetVariable(evt.target.value)}
                />
              </div>
              <div className="form-group mt-4">
                <label className="form-label">Actions</label>
                <textarea
                  className="form-control"
                  rows={6}
                  style={{ whiteSpace: "pre-wrap" }}
                  value={actions}
                  disabled={!owned}
                  onChange={(evt) => setActions(evt.target.value)}
                />
              </div>
              <div className="form-group mt-4">
                <label className="form-label">Siblings</label>
                <input
                  type="text"
                  className="form-control"
                  value={siblings}
                  disabled={!owned}
                  onChange={(evt) => setSiblings(evt.target.value)}
                />
              </div>
              <hr />
              {owned && (
                <button
                  type="button"
                  className="btn btn-primary mt-4"
                  disabled={saving}
                  onClick={save}
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </div>
        {owned && (
          <div className="col-12 col-md-6">
            <div className="card">
              <div className="card-header pb-0">
                <h5 className="card-title mb-0">Media</h5>
              </div>
              <div className="card-body">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    value={mediaPath}
                    onChange={(evt) => setMediaPath(evt.target.value)}
                  />
                </div>
                <div className="form-group mt-3">
                  <MediaFileGallery
                    selectedFileName={mediaPath}
                    onChange={(fileName, type) => {
                      setMediaPath(fileName);
                      setMediaType(type);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
