import {API} from './API';
import {Game} from "../models/Game";
import {Page} from "./Page";

export const loadGame = (id: string): Promise<Game> => {
    return API.get<Game>(`game/${id}`);
}

export const loadGames = (): Promise<Game[]> => {
    return API.get<Game[]>('game');
}

export const loadGamesPage = (page?: number, size?: number, sort?: string, dir?: string, filter?: string): Promise<Page<Game>> => {
    return API.get<Page<Game>>('game/page?' + API.buildQueryParams({
        page,
        size,
        sort,
        dir,
        filter
    }));
}

export const updateGame = (game: Game): Promise<void> => {
    return API.put<void>(`game/${game.id}`, game);
}

export const createGame = (game: Game): Promise<Game> => {
    return API.post<Game>('game', game);
}
