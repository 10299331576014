import React from "react";
import { Game } from "../models/Game";
import { Link } from "react-router-dom";
import { loadGamesPage } from "../api/Game";
import { getPhotoZipUrl } from "../api/Photo";
import { formatIsoDateTime } from "../utils/DateUtils";
import { PaginatedTable } from "../components/PaginatedTable";
import { Breadcrumb } from "../components/Breadcrumb";

const TABLE_COLUMNS = [
  {
    id: "name",
    name: "Name",
    selector: (game: Game) => game.name,
    sortable: true,
  },
  {
    id: "created_at",
    name: "Created",
    selector: (game: Game) => formatIsoDateTime(game.created_at),
    sortable: true,
  },
  {
    name: "View",
    button: true,
    sortable: false,
    cell: (game: Game) => (
      <Link to={`/games/${game.id}`} className="btn btn-primary">
        <i className="fa-solid fa-file-pen"></i>
      </Link>
    ),
  },
  {
    name: "Follow",
    button: true,
    sortable: false,
    cell: (game: Game) => (
      <Link to={`/games/${game.id}/follow`} className="btn btn-primary">
        <i className="fa-solid fa-eye"></i>
      </Link>
    ),
  },
  {
    name: "Gallery",
    button: true,
    sortable: false,
    cell: (game: Game) => (
      <Link to={`/games/${game.id}/gallery`} className="btn btn-primary">
        <i className="fa-regular fa-images"></i>
      </Link>
    ),
  },
  {
    name: "Photo Zip",
    button: true,
    sortable: false,
    cell: (game: Game) => (
      <a href={getPhotoZipUrl(game.id.toString())} className="btn btn-primary">
        <i className="fa-regular fa-file-zipper"></i>
      </a>
    ),
  },
  {
    name: "Install",
    button: true,
    sortable: false,
    cell: (game: Game) => (
      <Link to={`/games/${game.id}/install`} className="btn btn-primary">
        <i className="fa-solid fa-download"></i>
      </Link>
    ),
  },
];

export const GamesPage = () => {
  return (
    <>
      <Breadcrumb items={[{ text: "Games", link: "/games" }]} />
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header pb-0">
              <div className="float-end">
                <Link to="/games/new" className="btn btn-primary">
                  New Game <i className="fa-solid fa-circle-plus"></i>
                </Link>
              </div>
              <h5 className="card-title mb-0">Games</h5>
            </div>
            <div className="card-body">
              <PaginatedTable
                columns={TABLE_COLUMNS}
                loadPage={loadGamesPage}
                filterable
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
