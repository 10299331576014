import React, { useState } from "react";
import { API } from "../../api/API";

export const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const login = async () => {
    const ok = await API.login(email, password);

    if (ok) {
      window.location.reload();
    }
  };

  return (
    <div className="row h-100">
      <div className="col-3"></div>
      <div className="col-6">
        <div className="card">
          {/*<div className="card-header">Login</div>*/}
          <div className="card-body">
            <div className="row">
              <div className="col-12">
                <img
                  src="/logo512.png"
                  className="w-100 rounded"
                  alt="Cajam Games"
                />
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12">
                <form
                  action=""
                  onSubmit={(evt) => {
                    evt.preventDefault();
                    login();
                  }}
                >
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="text"
                      className="form-control"
                      value={email}
                      onChange={(evt) => setEmail(evt.target.value)}
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label>Password</label>
                    <input
                      type="password"
                      className="form-control"
                      value={password}
                      onChange={(evt) => setPassword(evt.target.value)}
                    />
                  </div>
                  <button type="submit" className="btn btn-primary mt-3">
                    Log In
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-3"></div>
    </div>
  );
};
