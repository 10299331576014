import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Company } from "../models/Company";
import { createCompany, loadCompany, updateCompany } from "../api/Company";
import { PaginatedTable } from "../components/PaginatedTable";
import { loadUsersPageByCompany } from "../api/User";
import { User } from "../models/User";
import { Breadcrumb } from "../components/Breadcrumb";

const TABLE_COLUMNS = [
  {
    id: "name",
    name: "Name",
    selector: (user: User) => user.name,
    sortable: true,
    grow: 0.45,
  },
  {
    id: "email",
    name: "Email",
    selector: (user: User) => user.email,
    sortable: true,
    grow: 0.45,
  },
  {
    name: "",
    cell: (user: User) => (
      <Link
        to={`/companies/${user.company_id}/users/${user.id}`}
        className="btn btn-primary"
      >
        <i className="fa-solid fa-file-pen"></i>
      </Link>
    ),
    sortable: false,
    grow: 0.1,
  },
];

export const CompanyDetails = () => {
  const [company, setCompany] = useState<Company>();
  const [name, setName] = useState("");
  const [saving, setSaving] = useState(false);

  const { companyId } = useParams();
  const navigate = useNavigate();

  const save = () => {
    if (saving || !companyId) {
      return;
    }

    setSaving(true);

    const data = {
      name,
    };

    if (companyId === "new") {
      createCompany({
        ...data,
        id: 0,
      })
        .then((res) => {
          toast.success(`The company "${name}" was created.`);
          navigate(`/companies/${res.id}`);
        })
        .catch((error) => {
          toast.error("Failed to create company.");
        })
        .finally(() => setSaving(false));
    } else {
      if (!company) {
        return;
      }

      updateCompany({
        ...company,
        ...data,
      })
        .then(() => toast.success(`The company "${name}" was saved."`))
        .catch((error) => toast.error("Failed to save company."))
        .finally(() => setSaving(false));
    }
  };

  useEffect(() => {
    if (!companyId) {
      return;
    }

    if (companyId === "new") {
      //
    } else {
      loadCompany(companyId).then((loadedCompany) => {
        setCompany(loadedCompany);
        setName(loadedCompany.name);
      });
    }
  }, [companyId]);

  return (
    <>
      <Breadcrumb
        items={[
          { text: "Companies", link: "/companies" },
          { text: company?.name || "", link: `/companies/${companyId}` },
        ]}
      />

      <div className="row">
        <div className="col-12 col-md-6">
          <div className="card">
            <div className="card-header pb-0">
              <h5 className="card-title mb-0">Settings</h5>
            </div>
            <div className="card-body">
              <div className="form-group">
                <label className="form-label">Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={(evt) => setName(evt.target.value)}
                />
              </div>

              <button
                type="button"
                className="btn btn-primary mt-4"
                disabled={saving}
                onClick={save}
              >
                Save
              </button>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="card">
            <div className="card-header pb-0">
              <h5 className="card-title mb-0">More</h5>
            </div>
            <div className="card-body">
              <table className="table table-sm">
                <thead>
                  <tr>
                    <th>Billing</th>
                  </tr>
                </thead>
                <tbody>
                  {!!company && (
                    <tr>
                      <td>
                        <Link
                          to={`/companies/${companyId}/billing`}
                          className="btn btn-primary"
                        >
                          <i className="fas fa-money-check-alt"></i>
                        </Link>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="card">
            <div className="card-header pb-0">
              <div className="float-end">
                <Link
                  to={`/companies/${companyId}/users/new`}
                  className="btn btn-primary btn-sm"
                >
                  New User <i className="fa-solid fa-circle-plus"></i>
                </Link>
              </div>
              <h5 className="card-title mb-0">Users</h5>
            </div>
            <div className="card-body">
              {!!companyId && (
                <PaginatedTable
                  columns={TABLE_COLUMNS}
                  loadPage={(
                    page: number,
                    size: number,
                    sort: string,
                    dir: string,
                    filter: string
                  ) =>
                    loadUsersPageByCompany(
                      companyId,
                      page,
                      size,
                      sort,
                      dir,
                      filter
                    )
                  }
                  defaultSortField="name"
                  defaultSortAsc
                  filterable
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
