import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { loadDevice } from "../api/Device";
import { Device } from "../models/Device";
import { Breadcrumb } from "../components/Breadcrumb";

export const DeviceDetailsPage = () => {
  const [device, setDevice] = useState<Device>();
  const [saving, setSaving] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    if (!id) {
      return;
    }

    loadDevice(parseInt(id, 10)).then((device) => {
      setDevice(device);
    });
  }, [id]);

  return (
    <>
      <Breadcrumb
        items={[
          { text: "Devices", link: "/devices" },
          { text: device?.name || "", link: `/devices/${id}` },
        ]}
      />

      <div className="row">
        <div className="col-12 col-md-6">
          <div className="card">
            <div className="card-header pb-0">
              <h5 className="card-title mb-0">Settings</h5>
            </div>
            <div className="card-body">
              <div className="form-group">
                <label className="form-label">Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={device?.name}
                  disabled
                />
              </div>
              <div className="form-group mt-4">
                <label className="form-label">Phone number</label>
                <input
                  type="text"
                  className="form-control"
                  value={device?.phone_number}
                  disabled
                />
              </div>
              <div className="form-group mt-4">
                <label className="form-label">Device ID</label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={device?.device_id}
                />
              </div>
              <br />
              <span className="text-muted">
                Changes needs to be made from the CG Admin app on the device.
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
