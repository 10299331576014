import React from "react";

import { Map } from "../models/Map";
import { Link } from "react-router-dom";
import { loadMapsPage } from "../api/Map";
import { PaginatedTable } from "../components/PaginatedTable";
import { Breadcrumb } from "../components/Breadcrumb";
import { OwnershipIndicator } from "../components/OwnershipIndicator";

const TABLE_COLUMNS = [
  {
    id: "name",
    name: "Name",
    selector: (map: Map) => map.name,
    sortable: true,
  },
  {
    id: "mission_count",
    name: "Map Missions",
    selector: (map: Map) => map.mission_count || "",
    sortable: true,
  },
  // {
  //   id: "template_count",
  //   name: "Templates",
  //   selector: (map: Map) => map.template_count || "",
  //   sortable: true,
  // },
  {
    name: "Owned By You",
    cell: (map: Map) => <OwnershipIndicator item={map} />,
  },
  {
    name: "View",
    button: true,
    sortable: false,
    cell: (map: Map) => (
      <Link to={`/maps/${map.id}`} className="btn btn-primary">
        <i className="fa-solid fa-file-pen"></i>
      </Link>
    ),
  },
];

export const MapsPage = () => {
  return (
    <>
      <Breadcrumb items={[{ text: "Maps", link: "/maps" }]} />
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header pb-0">
              <div className="float-end">
                <Link to="/maps/new" className="btn btn-primary">
                  New Map <i className="fa-solid fa-circle-plus"></i>
                </Link>
              </div>
              <h5 className="card-title mb-0">Maps</h5>
            </div>
            <div className="card-body">
              <PaginatedTable
                columns={TABLE_COLUMNS}
                loadPage={loadMapsPage}
                filterable
                defaultSortField="name"
                defaultSortAsc
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
