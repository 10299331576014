import { Link } from "react-router-dom";

interface BreadcrumbItem {
  link: string;
  text: string;
}

interface BreadcrumbProps {
  items: BreadcrumbItem[];
}

export const Breadcrumb = ({ items }: BreadcrumbProps) => (
  <div className="row">
    <div className="col-12">
      <div className="card">
        <div className="card-body py-3 px-4">
          {[{ text: "Cajam Games", link: "/" }, ...items].map((item, index) => (
            <span key={index.toString()}>
              <Link to={item.link} className="me-1">
                {item.text}
              </Link>
              {index < items.length && <span className="me-1 fw-bold">/</span>}
            </span>
          ))}
        </div>
      </div>
    </div>
  </div>
);
