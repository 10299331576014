import { useEffect, useState } from "react";
import { Page } from "../api/Page";
import { loadNewsPostPage } from "../api/NewsPost";
import DataTable from "react-data-table-component";
import { formatIsoDateTime } from "../utils/DateUtils";

const TABLE_COLUMS = [
  {
    cell: (row: any, index: any, column: any, id: any) => (
      <div>
        <h5 className="mb-0 mt-2">{row.title}</h5>
        <small>{formatIsoDateTime(row.created_at)}</small>
        <p className="mt-2" dangerouslySetInnerHTML={{ __html: row.text }} />
      </div>
    ),
  },
];

const PER_PAGE = 5;

export const NewsPostsTable = () => {
  const [page, setPage] = useState<Page<any>>();
  const [pageNum, setPageNum] = useState(1);
  const [loading, setLoading] = useState(false);

  const loadPageData = () => {
    setLoading(true);
    loadNewsPostPage(pageNum, PER_PAGE, "created_at", "desc", "")
      .then(setPage)
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    loadPageData();
  }, [pageNum]);

  return (
    <DataTable
      columns={TABLE_COLUMS}
      data={page?.data || []}
      pagination
      progressPending={loading}
      paginationServer
      paginationPerPage={PER_PAGE}
      paginationTotalRows={page?.total}
      noTableHead
      paginationComponentOptions={{ noRowsPerPage: true }}
      onChangePage={(page, totalRows) => {
        setPageNum(page);
      }}
    />
  );
};
