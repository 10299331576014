import { API } from "./API";
import { Company } from "../models/Company";
import { Page } from "./Page";

export const loadCompany = (companyId: string): Promise<Company> => {
  return API.get<Company>(`company/${companyId}`);
};

export const loadCompaniesPage = (
  page?: number,
  size?: number,
  sort?: string,
  dir?: string,
  filter?: string
): Promise<Page<Company>> => {
  return API.get<Page<Company>>(
    "company/page?" +
      API.buildQueryParams({
        page,
        size,
        sort,
        dir,
        filter,
      })
  );
};

export const updateCompany = (company: Company): Promise<Company> => {
  return API.put<Company>(`company/${company.id}`, company);
};

export const createCompany = (company: Company): Promise<Company> => {
  return API.post<Company>("company", company);
};
