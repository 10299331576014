import { Page } from "./Page";
import { API } from "./API";
import { NewsPost } from "../models/NewsPost";

export const loadNewsPostPage = (
  page?: number,
  size?: number,
  sort?: string,
  dir?: string,
  filter?: string
): Promise<Page<NewsPost>> => {
  return API.get<Page<NewsPost>>(
    "news_post/page?" +
      API.buildQueryParams({
        page,
        size,
        sort,
        dir,
        filter,
      })
  );
};
