import { API } from "./API";
import { User } from "../models/User";
import { Page } from "./Page";

export const loadCurrentUser = (): Promise<User> => {
  return API.get<User>(`user/current`);
};

export const loadUser = (userId: string): Promise<User> => {
  return API.get<User>(`user/${userId}`);
};

export const loadUsersPageByCompany = (
  companyId: string,
  page?: number,
  size?: number,
  sort?: string,
  dir?: string,
  filter?: string
): Promise<Page<User>> => {
  return API.get<Page<User>>(
    `company/${companyId}/user?` +
      API.buildQueryParams({
        page,
        size,
        sort,
        dir,
        filter,
      })
  );
};

export const updateUser = (user: User): Promise<User> => {
  return API.put<User>(`user/${user.id}`, user);
};

export const createUser = (user: User): Promise<User> => {
  return API.post<User>("user", user);
};
