import React, { useEffect, useState } from "react";
import "./App.css";

import "react-toastify/dist/ReactToastify.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
//import "bootswatch/dist/zephyr/bootstrap.min.css";
import { AuthRouter } from "./routers/AuthRouter";
import { MainRouter } from "./routers/MainRouter";
import { ToastContainer } from "react-toastify";

function App() {
  const [signedIn, setSignedIn] = useState(false);

  useEffect(() => {
    setSignedIn(!!localStorage.getItem("api_token"));
  }, []);

  return (
    <div className="wrapper min-vh-100">
      {!signedIn && <AuthRouter />}
      {signedIn && <MainRouter />}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        newestOnTop
        closeOnClick
        pauseOnFocusLoss
        pauseOnHover
        theme="colored"
        style={{ paddingTop: 60 }}
      />
    </div>
  );
}

export default App;
