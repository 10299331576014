import React, { useEffect, useRef, useState } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { Photo } from "../models/Photo";
import { useParams } from "react-router-dom";

import "@splidejs/react-splide/css";
import { getPhotoUrl, loadPhotoList } from "../api/Photo";
import { loadMissions } from "../api/Mission";
import { DeviceParticipation } from "../models/DeviceParticipation";
import { loadDeviceParticipationsByGame } from "../api/Device";

export const GameGalleryPage = () => {
  const [photos, setPhotos] = useState<Photo[]>([]);
  const [missionTexts, setMissionTexts] = useState<{ [key: number]: string }>(
    {}
  );
  const [deviceParticipations, setDeviceParticipations] = useState<
    DeviceParticipation[]
  >([]);
  const splideRef = useRef<any>();
  const { id } = useParams();

  useEffect(() => {
    loadMissions().then((res) => {
      const texts: { [key: number]: string } = {};

      for (const mission of res) {
        texts[mission.id] = mission.short_text || mission.text;
      }

      setMissionTexts(texts);
    });
  }, []);

  useEffect(() => {
    if (!id) {
      return;
    }

    loadPhotoList(id).then((res) =>
      setPhotos(res.filter((photo) => !photo.checked || photo.approved))
    );

    loadDeviceParticipationsByGame(parseInt(id, 10)).then(
      setDeviceParticipations
    );
  }, [id]);

  const getMissionText = (missionId: number) => {
    return missionTexts[missionId] || "";
  };

  const getTeamName = (deviceId: number) => {
    return (
      deviceParticipations.find(
        (participation) => participation.device_id === deviceId
      )?.team_name || ""
    );
  };

  return (
    <div className="gallery-container">
      {!!id && photos.length > 0 && (
        <Splide
          options={{
            type: "loop",
            width: "100vw",
            height: "100vh",
            fixedWidth: "100%",
            fixedHeight: "100vh",
            paginationKeyboard: true,
            keyboard: true,
            drag: true,
            flickMaxPages: 1,
            autoplay: true,
            interval: 10_000,
            preloadPages: 9999,
          }}
          ref={splideRef}
        >
          {photos.map((photo) => (
            <SplideSlide key={photo.file_name}>
              <img
                src={getPhotoUrl(id, photo.file_name)}
                className="slide-image"
              />
              <div className="gallery-text">
                <span>{getTeamName(photo.device_id)}</span>
                <br />
                <span>{getMissionText(photo.mission_id)}</span>
              </div>
            </SplideSlide>
          ))}
        </Splide>
      )}
    </div>
  );
};
