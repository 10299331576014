import React from "react";
import { Link } from "react-router-dom";
import { Block } from "../models/Block";
import { loadBlocksPage } from "../api/Block";
import { PaginatedTable } from "../components/PaginatedTable";
import { Breadcrumb } from "../components/Breadcrumb";
import { Map } from "../models/Map";
import { OwnershipIndicator } from "../components/OwnershipIndicator";

const TABLE_COLUMNS = [
  {
    id: "name",
    name: "Name",
    selector: (block: Block) =>
      `${block.name} ${block.final ? "[END BLOCK]" : ""}`,
    sortable: true,
  },
  {
    id: "templates",
    name: "Templates",
    selector: (block: Block) => block.template_count || "",
    sortable: true,
  },
  {
    id: "missions",
    name: "Missions",
    selector: (block: Block) => block.mission_count || "",
    sortable: true,
  },
  {
    name: "Owned By You",
    cell: (map: Map) => <OwnershipIndicator item={map} />,
  },
  {
    name: "View",
    button: true,
    sortable: false,
    cell: (block: Block) => (
      <Link to={`/blocks/${block.id}`} className="btn btn-primary">
        <i className="fa-solid fa-file-pen"></i>
      </Link>
    ),
  },
];

export const BlocksPage = () => {
  return (
    <>
      <Breadcrumb items={[{ text: "Blocks", link: "/blocks" }]} />
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header pb-0">
              <div className="float-end">
                <Link to="/blocks/new" className="btn btn-primary">
                  New Block <i className="fa-solid fa-circle-plus"></i>
                </Link>
              </div>
              <h5 className="card-title mb-0">Blocks</h5>
            </div>
            <div className="card-body">
              <PaginatedTable
                columns={TABLE_COLUMNS}
                loadPage={loadBlocksPage}
                defaultSortField="name"
                defaultSortAsc
                filterable
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
