import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Map } from "../models/Map";
import { createMap, loadMap, updateMap } from "../api/Map";
import { MapMission } from "../models/MapMission";
import {
  CircleMarker,
  MapContainer,
  TileLayer,
  Tooltip,
  useMap,
} from "react-leaflet";

import "leaflet/dist/leaflet.css";
import { MediaFileGallery } from "../components/MediaFileGallery";
import { LatLngExpression } from "leaflet";
import { toast } from "react-toastify";
import { Breadcrumb } from "../components/Breadcrumb";
import { Context } from "../Context";

interface MapCentererProps {
  center: LatLngExpression;
}

const MapCenterer = ({ center }: MapCentererProps) => {
  const map = useMap();

  useEffect(() => {
    if (!map || !center) {
      return;
    }

    map.setView(center);
  }, [map, center]);

  return <></>;
};

export const MapDetailsPage = () => {
  const [map, setMap] = useState<Map>();
  const [name, setName] = useState("");
  const [missions, setMissions] = useState<MapMission[]>([]);
  const [homeLatitude, setHomeLatitude] = useState("");
  const [homeLongitude, setHomeLongitude] = useState("");
  const [gallerySelectedFileName, setGallerySelectedFileName] = useState("");
  const [gallerySelectedFileType, setGallerySelectedFileType] = useState("");
  const [saving, setSaving] = useState(false);
  const [mapCenter, setMapCenter] = useState<LatLngExpression>([0, 0]);
  const [owned, setOwned] = useState(false);
  const { id } = useParams();

  const navigate = useNavigate();
  const context = useContext(Context);

  const parsePastedCoordinates = (str: string): string[] | null => {
    const parts = str.replace(/\s/, "").split(/[,;]/);

    if (parts.length <= 1) {
      return null;
    }

    return parts;
  };

  useEffect(() => {
    if (!context?.user) {
      return;
    }

    setOwned(
      context.user.company_id == map?.company_id ||
        !!context.user.is_superadmin ||
        id === "new"
    );
  }, [context, map, id]);

  useEffect(() => {
    if (!id) {
      return;
    }

    if (id !== "new") {
      loadMap(id).then((res) => {
        setMap(res);
        setName(res.name);
        setMissions(
          (res.missions || []).map((mission) => {
            mission.text = mission.text.replace(/\\n/g, "\n");
            return mission;
          })
        );
        setHomeLatitude(res.home_latitude.toString());
        setHomeLongitude(res.home_longitude.toString());
      });
    }
  }, [id]);

  useEffect(() => {
    const lon = parseFloat(homeLongitude);
    const lat = parseFloat(homeLatitude);

    if (lon && lat) {
      setMapCenter([lon, lat]);
    }
  }, [homeLongitude, homeLatitude]);

  const save = () => {
    if (saving || !context?.user) {
      return;
    }

    setSaving(true);

    const data = {
      name,
      missions: missions.map((mission) => ({
        ...mission,
        answers_correct: mission.answers_correct.replace(/\n/g, "|"),
      })),
      home_latitude: parseFloat(homeLatitude),
      home_longitude: parseFloat(homeLongitude),
      points_correct: 150,
      points_incorrect: 0,
    };

    if (id === "new") {
      createMap({
        ...data,
        id: 0,
        company_id: context.user.company_id,
        is_public: false,
      })
        .then((res) => {
          toast.success(`The map "${name} was created."`);
          navigate(`/maps/${res.id}`);
        })
        .catch((error) => toast.error("Failed to create map."))
        .finally(() => {
          setSaving(false);
        });
    } else {
      if (!map) {
        return;
      }

      updateMap({
        ...map,
        ...data,
      })
        .then(() => toast.success(`The map "${name}" was saved.`))
        .catch((error) => toast.error("Failed to save map."))
        .finally(() => {
          setSaving(false);
        });
    }
  };

  const deletePoint = (index: number) => {
    const newMissions = [...missions];
    newMissions.splice(index, 1);
    setMissions(newMissions);
  };

  const updatePoint = (index: number, key: string, value: any) => {
    const newMissions = [...missions];
    // @ts-ignore
    newMissions[index][key] = value;
    setMissions(newMissions);
  };

  const addPoint = () => {
    if (!context?.user) {
      return;
    }

    const newMissions = [
      ...missions,
      {
        id: 0,
        marker_text: (missions.length + 1).toString(),
        text: "",
        short_text: "",
        points_correct: 150,
        points_incorrect: 0,
        answers_correct: "",
        latitude: "",
        longitude: "",
        media_path: "",
        media_type: "",
        company_id: context.user.company_id,
        is_public: false,
      },
    ];
    setMissions(newMissions);
  };

  return (
    <>
      <Breadcrumb
        items={[
          { text: "Maps", link: "/maps" },
          { text: name || "", link: `/maps/${map?.id}` },
        ]}
      />
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="card">
            <div className="card-header pb-0">
              <h5 className="card-title mb-0">Settings</h5>
            </div>
            <div className="card-body">
              <div className="form-group">
                <label className="form-label">Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  disabled={!owned}
                  onChange={(evt) => setName(evt.target.value)}
                />
              </div>
              <div className="form-group mt-3">
                <label className="form-label">Home Longitude</label>
                <input
                  type="text"
                  className="form-control"
                  value={homeLongitude}
                  disabled={!owned}
                  onChange={(evt) => {
                    const parsed = parsePastedCoordinates(evt.target.value);

                    if (parsed) {
                      setHomeLongitude(parsed[0]);
                      setHomeLatitude(parsed[1]);
                      return;
                    }

                    setHomeLongitude(evt.target.value);
                  }}
                />
              </div>
              <div className="form-group mt-3">
                <label className="form-label">Home Latitude</label>
                <input
                  type="text"
                  className="form-control"
                  value={homeLatitude}
                  disabled={!owned}
                  onChange={(evt) => {
                    const parsed = parsePastedCoordinates(evt.target.value);

                    if (parsed) {
                      setHomeLongitude(parsed[0]);
                      setHomeLatitude(parsed[1]);
                      return;
                    }

                    setHomeLatitude(evt.target.value);
                  }}
                />
              </div>
              {owned && (
                <button
                  type="button"
                  className="btn btn-primary mt-4"
                  disabled={saving}
                  onClick={save}
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="card">
            <div className="card-header pb-0">
              <h5 className="card-title mb-0">Map</h5>
            </div>
            <div className="card-body">
              {!!map && (
                <MapContainer
                  center={mapCenter}
                  zoom={14}
                  style={{ height: 400 }}
                >
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <MapCenterer center={mapCenter} />
                  {parseFloat(homeLongitude) && parseFloat(homeLatitude) && (
                    <CircleMarker
                      key={"HOME"}
                      center={[
                        parseFloat(homeLongitude),
                        parseFloat(homeLatitude),
                      ]}
                      pathOptions={{
                        color: "red",
                        fill: true,
                        fillOpacity: 1.0,
                      }}
                      radius={5}
                    >
                      <Tooltip
                        direction="top"
                        offset={[0, -3]}
                        opacity={1.0}
                        permanent
                      >
                        HOME
                      </Tooltip>
                    </CircleMarker>
                  )}

                  {missions
                    ?.filter(
                      (mission) => !!mission.latitude && !!mission.longitude
                    )
                    .map((mission) => (
                      <CircleMarker
                        key={mission.id}
                        center={[
                          parseFloat(mission.longitude),
                          parseFloat(mission.latitude),
                        ]}
                        pathOptions={{
                          color: "red",
                          fill: true,
                          fillOpacity: 1.0,
                        }}
                        radius={5}
                      >
                        <Tooltip
                          direction="top"
                          offset={[0, -3]}
                          opacity={1.0}
                          permanent
                        >
                          {mission.marker_text}
                        </Tooltip>
                      </CircleMarker>
                    ))}
                </MapContainer>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header pb-0">
                <div className="float-end">
                  {owned && (
                    <button className="btn btn-primary" onClick={addPoint}>
                      Add point
                    </button>
                  )}
                </div>
                <h5 className="card-title mb-0">Map Missions</h5>
              </div>
              <div className="card-body">
                <table className="table table-bordered table-striped table-sm mt-3">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Longitude</th>
                      <th>Latitude</th>
                      <th>Question</th>
                      <th>Answer</th>
                      <th>Media path</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {missions?.map((mission, index) => (
                      <tr key={mission.id}>
                        <td>{mission.marker_text}</td>
                        <td>
                          <textarea
                            rows={3}
                            className="w-100"
                            style={{ whiteSpace: "pre-wrap" }}
                            value={mission.longitude}
                            disabled={!owned}
                            onChange={(evt) => {
                              const parsed = parsePastedCoordinates(
                                evt.target.value
                              );

                              if (parsed) {
                                updatePoint(index, "longitude", parsed[0]);
                                updatePoint(index, "latitude", parsed[1]);
                                return;
                              }

                              updatePoint(index, "longitude", evt.target.value);
                            }}
                          />
                        </td>
                        <td>
                          <textarea
                            rows={3}
                            className="w-100"
                            style={{ whiteSpace: "pre-wrap" }}
                            value={mission.latitude}
                            disabled={!owned}
                            onChange={(evt) => {
                              const parsed = parsePastedCoordinates(
                                evt.target.value
                              );

                              if (parsed) {
                                updatePoint(index, "longitude", parsed[0]);
                                updatePoint(index, "latitude", parsed[1]);
                                return;
                              }

                              updatePoint(index, "latitude", evt.target.value);
                            }}
                          />
                        </td>

                        <td>
                          <textarea
                            rows={3}
                            className="w-100"
                            style={{ whiteSpace: "pre-wrap" }}
                            value={mission.text}
                            disabled={!owned}
                            onChange={(evt) =>
                              updatePoint(index, "text", evt.target.value)
                            }
                          />
                        </td>
                        <td>
                          <textarea
                            rows={3}
                            className="w-100"
                            style={{ whiteSpace: "pre-wrap" }}
                            value={mission.answers_correct}
                            disabled={!owned}
                            onChange={(evt) =>
                              updatePoint(
                                index,
                                "answers_correct",
                                evt.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <textarea
                            rows={3}
                            className="w-75"
                            style={{ whiteSpace: "pre-wrap" }}
                            value={mission.media_path}
                            disabled={!owned}
                            onChange={(evt) =>
                              updatePoint(index, "media_path", evt.target.value)
                            }
                          />
                          {owned && (
                            <button
                              type="button"
                              className="btn btn-sm btn-primary ms-2"
                              style={{ marginTop: -70 }}
                              onClick={() => {
                                if (
                                  !gallerySelectedFileName ||
                                  !gallerySelectedFileType
                                ) {
                                  return;
                                }

                                updatePoint(
                                  index,
                                  "media_path",
                                  gallerySelectedFileName
                                );
                                updatePoint(
                                  index,
                                  "media_type",
                                  gallerySelectedFileType
                                );
                              }}
                            >
                              <i className="fa-solid fa-arrow-left-long"></i>
                            </button>
                          )}
                        </td>
                        <td>
                          {owned && (
                            <button
                              className="btn btn-sm btn-danger ms-2"
                              style={{ marginTop: 32 }}
                              onClick={() => deletePoint(index)}
                            >
                              <i className="fa-solid fa-xmark"></i>
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {owned && (
          <div className="col-12 col-md-6">
            <div className="card">
              <div className="card-header pb-0">
                <h5 className="card-title mb-0">Media</h5>
              </div>
              <div className="card-body">
                <p>
                  Select a file in the gallery, then press the button in the
                  "Media path" column in a mission.
                </p>
                <MediaFileGallery
                  selectedFileName={gallerySelectedFileName}
                  onChange={(fileName, type) => {
                    setGallerySelectedFileName(fileName);
                    setGallerySelectedFileType(type);
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
