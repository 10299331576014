import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { useParams } from "react-router-dom";
import { Game } from "../models/Game";
import { loadGame } from "../api/Game";
import { Breadcrumb } from "../components/Breadcrumb";

export const GameInstallPage = () => {
  const [game, setGame] = useState<Game>();
  const [qrData, setQrData] = useState("");
  const { id } = useParams();

  useEffect(() => {
    if (!id) {
      return;
    }

    loadGame(id).then((res) => {
      setGame(res);
      setQrData(
        JSON.stringify({
          id: res.id,
          name: res.name,
        })
      );
    });
  }, [id]);

  return (
    <>
      <Breadcrumb
        items={[
          { text: "Games", link: "/games" },
          { text: game?.name || "", link: `/games/${game?.id}` },
          { text: "Install", link: `/games/${game?.id}/install` },
        ]}
      />
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="card">
            <div className="card-header pb-0">
              <h5 className="card-title mb-0">Install Game</h5>
            </div>
            <div className="card-body">
              <ol>
                <li>
                  Open <em>CG Admin</em> on the devices you want to install the
                  game to.
                </li>
                <li>
                  Press <em>Install new game</em>.
                </li>
                <li>
                  Scan the QR code and follow the instructions in{" "}
                  <em>CG Admin</em>.
                </li>
                <li>The game is now ready play.</li>
              </ol>
              <span className="text-muted">
                If you are unable to scan the QR code, you can also use the
                "Install By Manual ID" function and enter id number{" "}
                <strong>{game?.id}</strong>.
              </span>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="card">
            <div className="card-header pb-0">
              <h5 className="card-title mb-0">QR Code</h5>
            </div>
            <div className="card-body">
              <QRCode value={qrData} level="M" size={256} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
