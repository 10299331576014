import React, { useEffect, useRef, useState } from "react";
import { Page } from "../api/Page";
import DataTable, { TableColumn } from "react-data-table-component";

const EDIT_TIMEOUT = 500;

interface Props {
  columns: TableColumn<any>[];
  defaultSortField?: string;
  defaultSortAsc?: boolean;
  loadPage: (
    page: number,
    size: number,
    sort: string,
    dir: string,
    filter: string
  ) => Promise<Page<any>>;
  filterable?: boolean;
}

export const PaginatedTable = ({
  columns,
  defaultSortField = "created_at",
  defaultSortAsc,
  loadPage,
  filterable = true,
}: Props) => {
  const [page, setPage] = useState<Page<any>>();
  const [pageNum, setPageNum] = useState(1);
  const [size, setSize] = useState(25);
  const [sort, setSort] = useState(defaultSortField);
  const [dir, setDir] = useState(defaultSortAsc ? "asc" : "desc");
  const [filter, setFilter] = useState("");
  const [initialSort, setInitialSort] = useState(false);
  const [loading, setLoading] = useState(false);
  const timeoutRef = useRef<NodeJS.Timer>(null);

  const loadPageData = () => {
    setLoading(true);
    loadPage(pageNum, size, sort, dir, filter)
      .then(setPage)
      .finally(() => setLoading(false));
  };

  const startTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    setTimeout(() => {
      loadPageData();
    }, EDIT_TIMEOUT);
  };

  useEffect(() => {
    startTimeout();
  }, [pageNum, size, sort, dir, filter]);

  return (
    <>
      {filterable && (
        <input
          type="text"
          className="form-control form-control-sm mt-4 w-25"
          placeholder="Search..."
          value={filter}
          onChange={(evt) => {
            setFilter(evt.target.value);
          }}
        />
      )}
      <DataTable
        columns={columns}
        data={page?.data || []}
        pagination
        striped
        progressPending={loading}
        highlightOnHover
        persistTableHead
        paginationServer
        paginationTotalRows={page?.total}
        paginationPerPage={size}
        sortServer
        defaultSortFieldId={defaultSortField}
        defaultSortAsc={defaultSortAsc === true}
        onChangePage={(page, totalRows) => {
          setPageNum(page);
        }}
        onChangeRowsPerPage={(rowsPerPage, page) => {
          setSize(rowsPerPage);
        }}
        onSort={(column, direction, rows) => {
          if (!initialSort) {
            setInitialSort(true);
          }

          setSort(column.id as string);
          setDir(direction);
        }}
        paginationRowsPerPageOptions={[10, 25, 50]}
      />
    </>
  );
};
