export const BASE_URL = "https://api.cajamgames.se/api/";
//export const BASE_URL = "http://127.0.0.1:8000/api/";

export class API {
  private static checkAuth(res: any) {
    if ([401, 403].includes(res?.status)) {
      localStorage.removeItem("api_token");
      window.location.reload();
    }
  }

  public static login(email: string, password: string) {
    return fetch(`${BASE_URL}user/login`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status) {
          localStorage.setItem("api_token", res.token);
        }

        return res.status;
      });
  }

  public static logout() {
    localStorage.removeItem("api_token");
    window.location.reload();
  }

  public static get<T>(url: string): Promise<T> {
    return fetch(BASE_URL + url, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("api_token"),
        Accept: "application/json",
      },
    }).then((res) => {
      this.checkAuth(res);

      if (!res.ok) {
        throw new Error(res.statusText);
      }

      return res.json() as Promise<T>;
    });
  }

  public static post<T>(url: string, data: any): Promise<T> {
    return fetch(BASE_URL + url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Authorization: "Bearer " + localStorage.getItem("api_token"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) => {
      this.checkAuth(res);

      if (!res.ok) {
        throw new Error(res.statusText);
      }

      return res.json() as Promise<T>;
    });
  }

  public static put<T>(url: string, data: any): Promise<T> {
    return fetch(BASE_URL + url, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        Authorization: "Bearer " + localStorage.getItem("api_token"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) => {
      this.checkAuth(res);

      if (!res.ok) {
        throw new Error(res.statusText);
      }

      return res.json() as Promise<T>;
    });
  }

  public static delete<T>(url: string): Promise<T> {
    return fetch(BASE_URL + url, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("api_token"),
        Accept: "application/json",
      },
    }).then((res) => {
      this.checkAuth(res);

      if (!res.ok) {
        throw new Error(res.statusText);
      }

      return res.json() as Promise<T>;
    });
  }

  public static buildQueryParams(params: {}): string {
    const parts = [];

    for (const [key, value] of Object.entries(params)) {
      if (value) {
        parts.push(`${key}=${value}`);
      }
    }

    return parts.join("&");
  }
}
