import { API } from "./API";
import { DeviceStatus } from "../models/DeviceStatus";

export const getDeviceStatusByDevice = (
  deviceId: number
): Promise<DeviceStatus> => {
  return API.get<DeviceStatus>(`device_status/device/${deviceId}`);
};

export const loadDeviceStatusesByGame = (
  gameId: number
): Promise<DeviceStatus[]> => {
  return API.get<DeviceStatus[]>(`game/${gameId}/device_statuses`);
};
