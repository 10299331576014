import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Game } from "../models/Game";
import { loadTemplate, loadTemplatesPage } from "../api/Template";
import { Template } from "../models/Template";
import { createGame, loadGame, updateGame } from "../api/Game";
import { SearchableSelect } from "../components/SearchableSelect";
import { toast } from "react-toastify";
import { getPhotoZipUrl } from "../api/Photo";
import { Breadcrumb } from "../components/Breadcrumb";
import { Context } from "../Context";

export const GameDetailsPage = () => {
  const [game, setGame] = useState<Game>();
  const [name, setName] = useState("");
  const [emergencyNumber, setEmergencyNumber] = useState("");
  const [currentTemplate, setCurrentTemplate] = useState<Template>();
  const [saving, setSaving] = useState(false);
  const { id } = useParams();

  const navigate = useNavigate();
  const context = useContext(Context);

  useEffect(() => {
    if (!id) {
      return;
    }

    if (id !== "new") {
      loadGame(id).then((res) => {
        setGame(res);
        setName(res.name);
        setEmergencyNumber(res.emergency_number || "");

        loadTemplate(res.template_id.toString()).then(setCurrentTemplate);
      });
    }
  }, [id]);

  const save = () => {
    if (saving || !currentTemplate || !context?.user) {
      return;
    }

    setSaving(true);

    const data = {
      name,
      emergency_number: emergencyNumber,
      template_id: currentTemplate?.id,
    };

    if (id === "new") {
      createGame({
        ...data,
        id: 0,
        company_id: context.user.company_id,
        is_public: false,
      })
        .then((res) => {
          toast.success(`Game "${name}" created.`);
          navigate(`/games/${res.id}`);
        })
        .catch((error) => toast.error(`Failed to create game.`))
        .finally(() => {
          setSaving(false);
        });
    } else {
      if (!game) {
        return;
      }

      updateGame({
        ...game,
        ...data,
      })
        .then(() => toast.success(`Game "${name}" saved.`))
        .catch((error) => toast.error("Failed to save game."))
        .finally(() => {
          setSaving(false);
        });
    }
  };

  return (
    <>
      <Breadcrumb
        items={[
          { text: "Games", link: "/games" },
          { text: name || "", link: `/games/${game?.id}` },
        ]}
      />
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="card">
            <div className="card-header pb-0">
              <h5 className="card-title mb-0">Settings</h5>
            </div>
            <div className="card-body">
              <div className="form-group">
                <label className="form-label">Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={(evt) => setName(evt.target.value)}
                />
              </div>
              <div className="form-group mt-4">
                <label className="form-label">Emergency Number</label>
                <input
                  type="text"
                  className="form-control"
                  value={emergencyNumber}
                  onChange={(evt) => setEmergencyNumber(evt.target.value)}
                />
              </div>
              <div className="form-group mt-4">
                <label className="form-label">Template</label>
                <div className="row pe-3">
                  <div className="col-11">
                    {((!!game && !game.template_id) ||
                      !!currentTemplate ||
                      id === "new") && (
                      <SearchableSelect
                        labelField="name"
                        defaultValue={currentTemplate}
                        loadPage={loadTemplatesPage}
                        onChange={setCurrentTemplate}
                      />
                    )}
                  </div>
                  <div className="col-1">
                    {!!currentTemplate && (
                      <Link
                        to={`/templates/${currentTemplate.id}`}
                        target="_blank"
                        className="btn btn-primary"
                      >
                        <i className="fas fa-external-link-alt"></i>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
              <button
                type="button"
                className="btn btn-primary mt-4"
                disabled={saving}
                onClick={save}
              >
                Save
              </button>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="card">
            <div className="card-header pb-0">
              <h5 className="card-title mb-0">More</h5>
            </div>
            <div className="card-body">
              <table className="table table-sm">
                <thead>
                  <tr>
                    <th>Follow</th>
                    <th>Gallery</th>
                    <th>Photo Zip</th>
                    <th>Install</th>
                  </tr>
                </thead>
                <tbody>
                  {!!game && (
                    <tr>
                      <td>
                        <Link
                          to={`/games/${game.id}/follow`}
                          className="btn btn-primary"
                        >
                          <i className="fa-solid fa-eye"></i>
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/games/${game.id}/gallery`}
                          className="btn btn-primary"
                        >
                          <i className="fa-regular fa-images"></i>
                        </Link>
                      </td>
                      <td>
                        <a
                          href={getPhotoZipUrl(game.id.toString())}
                          className="btn btn-primary"
                        >
                          <i className="fa-regular fa-file-zipper"></i>
                        </a>
                      </td>
                      <td>
                        <Link
                          to={`/games/${game.id}/install`}
                          className="btn btn-primary"
                        >
                          <i className="fa-solid fa-download"></i>
                        </Link>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
