import AsyncSelect from "react-select/async";
import { Page } from "../api/Page";

interface Props {
  labelField: string;
  defaultValue?: any;
  loadPage: (
    page: number,
    size: number,
    sort: string,
    dir: string,
    filter: string
  ) => Promise<Page<any>>;
  onChange: (value: any) => void;
  getLabel?: (item: any) => string;
}

export const SearchableSelect = ({
  labelField,
  defaultValue,
  loadPage,
  onChange,
  getLabel,
}: Props) => {
  const search = async (query: string) => {
    const page = await loadPage(1, 50, labelField, "asc", query);
    return page.data;
  };

  return (
    <AsyncSelect
      defaultOptions
      loadOptions={search}
      getOptionValue={(item: any) => item.id}
      getOptionLabel={(item: any) => {
        if (getLabel) {
          return getLabel(item);
        }

        return item[labelField];
      }}
      isClearable
      captureMenuScroll
      closeMenuOnSelect
      isSearchable
      defaultValue={defaultValue}
      onChange={onChange}
    />
  );
};
