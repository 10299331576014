import { useContext } from "react";
import { Context } from "../Context";

interface OwnershipIndicatorProps {
  item: any;
}

export const OwnershipIndicator = ({ item }: OwnershipIndicatorProps) => {
  const context = useContext(Context);

  if (!context?.user) {
    return null;
  }

  return (
    <span>
      {context.user.company_id == item.company_id || context.user.is_superadmin
        ? "Yes"
        : "No"}
    </span>
  );
};
