import {API, BASE_URL} from "./API";

export interface MediaFileUploadResult {
    fileName: string;
};

export const loadMediaFileList = (): Promise<string[]> => {
    return API.get<string[]>('media_file');
}

export const uploadMediaFile = (file: File): Promise<MediaFileUploadResult> => {
    const formData = new FormData();
    formData.append('file', file);

    return fetch(BASE_URL + 'media_file', {
        method: 'POST',
        body: formData,
        headers: {
            //'Content-Type': 'multipart/form-data',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('api_token'),
        },
    })
        .then(res => {
            if (!res.ok) {
                throw new Error(res.statusText);
            }

            return res.json() as Promise<MediaFileUploadResult>;
        });
};

export const getMediaFileUrl = (fileName: string): string => `${BASE_URL}media_file/${encodeURIComponent(fileName)}`;
