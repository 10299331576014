import {API} from "./API";
import {SMS} from "../models/SMS";

export const sendSMSToDevice = (gameId: string, deviceId: number, text: string): Promise<void> => {
    return API.post<void>('sms', {
        game_id: gameId,
        device_id: deviceId,
        text,
    });
}

export const listSMSByGameAndDevice = (gameId: string, deviceId: number): Promise<SMS[]> => {
    return API.get<SMS[]>(`sms/${gameId}/${deviceId}`);
}
