import React, {useEffect, useState} from "react";
import {getMediaFileUrl, loadMediaFileList, uploadMediaFile} from "../api/MediaFile";

const EXTENSIONS_IMAGE = ['jpg', 'png', 'gif', 'jpeg'];
const EXTENSIONS_AUDIO = ['ogg', 'wav', 'mp3'];
const EXTENSIONS_VIDEO = ['mp4'];

interface Props {
    selectedFileName: string;
    onChange: (fileName: string, type: string) => void;
}

export const MediaFileGallery = (props: Props) => {
    const [fileNames, setFileNames] = useState<string[]>([]);
    const [uploadFile, setUploadFile] = useState<File>();
    const [fileKey, setFileKey] = useState(Date.now().toString());
    const [uploading, setUploading] = useState(false);

    useEffect(() => {
        loadMediaFileList()
            .then(res => setFileNames(res.sort((a, b) => a.toLowerCase() > b.toLowerCase() ? 1 : -1)));
    }, []);

    const upload = () => {
        if (!uploadFile || uploading) {
            return;
        }

        setUploading(true);

        uploadMediaFile(uploadFile)
            .then(res => {
                setFileNames([res.fileName, ...fileNames]);
                setUploadFile(undefined);
                setFileKey(Date.now().toString());
            })
            .finally(() => setUploading(false));

    };

    const renderUpload = () => {
        return (
            <>
                <div className="col-10 mb-3">
                    <div className="form-group">
                        <input type="file" key={fileKey} className="form-control" onChange={evt => {
                            if (!evt.target.files) {
                                return;
                            }

                            if (evt.target.files.length > 0) {
                                setUploadFile(evt.target.files[0]);
                            } else {
                                setUploadFile(undefined);
                            }
                        }}/>
                    </div>
                </div>
                <div className="col-2 mb-3">
                    <button type="button" className="btn btn-primary" disabled={!uploadFile || uploading}
                            onClick={upload}>
                        Upload
                    </button>
                </div>
            </>
        );
    };

    const renderThumbnail = (fileName: string) => {
        const parts = fileName.split('.');
        const extension = parts[parts.length - 1].toLowerCase();

        let content = null;
        let type = '';

        if (EXTENSIONS_IMAGE.includes(extension)) {
            type = 'image';
            content = <img src={getMediaFileUrl(fileName)} style={{width: '100%', height: '100px'}}/>
        } else if (EXTENSIONS_AUDIO.includes(extension)) {
            type = 'audio';
            content = (
                <div style={{width: '100%', height: '100px', backgroundColor: '#aaaaaa'}}>
                    <span style={{margin: 10}}>AUDIO</span>
                </div>
            );
        } else if (EXTENSIONS_VIDEO.includes(extension)) {
            type = 'video';
            content = (
                <div style={{width: '100%', height: '100px', backgroundColor: '#aaaaaa'}}>
                    <span style={{margin: 10}}>VIDEO</span>
                </div>
            );
        }

        return (
            <div className="col-4 mb-2"
                 style={(props.selectedFileName === fileName) ? {border: '3px solid #3459E6'} : {}}
                 onClick={() => props.onChange(fileName, type)}>
                {content}
                <span style={{
                    backgroundColor: '#00000080',
                    color: '#ffffff',
                    fontSize: 14,
                    padding: 4,
                    position: 'relative',
                    top: -26,
                }}>
                    {fileName}
                </span>
            </div>
        );
    };

    return (
        <div className="card bg-secondary w-100">
            <div className="card-body">
                <div className="row">
                    {renderUpload()}
                </div>
                <div className="row">
                    {fileNames.map(fileName => renderThumbnail(fileName))}
                </div>
            </div>
        </div>
    );
};
