import { BrowserRouter, Route, Routes } from "react-router-dom";
import React from "react";
import { LoginPage } from "../pages/auth/Login";

export const AuthRouter = () => (
  <BrowserRouter>
    <div className="container">
      <main className="content">
        <div className="container-fluid p-0">
          <Routes>
            <Route path="*" element={<LoginPage />} />
          </Routes>
        </div>
      </main>
    </div>
  </BrowserRouter>
);
