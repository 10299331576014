import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { HomePage } from "../pages/Home";
import { GamesPage } from "../pages/Games";
import { TemplatesPage } from "../pages/Templates";
import { BlocksPage } from "../pages/Blocks";
import { MissionsPage } from "../pages/Missions";
import { MapsPage } from "../pages/Maps";
import { StatisticsPage } from "../pages/Statistics";
import { GameDetailsPage } from "../pages/GameDetails";
import { GameFollowPage } from "../pages/GameFollow";
import { TemplateDetailsPage } from "../pages/TemplateDetails";
import { BlockDetailsPage } from "../pages/BlockDetails";
import { MissionDetails } from "../pages/MissionDetails";
import { MapDetailsPage } from "../pages/MapDetails";
import { GameInstallPage } from "../pages/GameInstall";
import { DevicesPage } from "../pages/Devices";
import { DeviceDetailsPage } from "../pages/DeviceDetails";
import { GameGalleryPage } from "../pages/GameGallery";
import { HelpPage } from "../pages/HelpPage";
import { UserDetails } from "../pages/UserDetails";
import { NotFound } from "../pages/NotFound";
import { CompanyDetails } from "../pages/CompanyDetails";
import { CompanyBilling } from "../pages/CompanyBilling";
import { Companies } from "../pages/Companies";
import { Sidebar } from "../components/Sidebar";
import { DeviceRegister } from "../pages/DeviceRegister";
import { Context } from "../Context";
import { User } from "../models/User";
import { Company } from "../models/Company";
import { loadCurrentUser } from "../api/User";
import { loadCompany } from "../api/Company";

export const MainRouter = () => {
  const [user, setUser] = useState<User>();
  const [company, setCompany] = useState<Company>();

  useEffect(() => {
    loadCurrentUser().then((user) => {
      setUser(user);
      loadCompany(user.company_id.toString()).then(setCompany);
    });
  }, []);

  return (
    <Context.Provider value={{ user, company }}>
      <BrowserRouter>
        <Sidebar />

        <div className="main">
          <a className="sidebar-toggle js-sidebar-toggle m-2">
            <i className="hamburger align-self-center"></i>
          </a>
          <main className="content pt-2">
            <div className="container-fluid p-0">
              <Routes>
                <Route path="/" element={<HomePage />} />

                <Route path="/games" element={<GamesPage />} />
                <Route path="/games/:id" element={<GameDetailsPage />} />
                <Route path="/games/:id/follow" element={<GameFollowPage />} />
                <Route
                  path="/games/:id/install"
                  element={<GameInstallPage />}
                />
                <Route
                  path="/games/:id/gallery"
                  element={<GameGalleryPage />}
                />

                <Route path="/templates" element={<TemplatesPage />} />
                <Route
                  path="/templates/:id"
                  element={<TemplateDetailsPage />}
                />

                <Route path="/blocks" element={<BlocksPage />} />
                <Route path="/blocks/:id" element={<BlockDetailsPage />} />

                <Route path="/missions" element={<MissionsPage />} />
                <Route path="/missions/:id" element={<MissionDetails />} />

                <Route path="/maps" element={<MapsPage />} />
                <Route path="/maps/:id" element={<MapDetailsPage />} />

                <Route path="/devices" element={<DevicesPage />} />
                <Route path="/devices/new" element={<DeviceRegister />} />
                <Route path="/devices/:id" element={<DeviceDetailsPage />} />

                <Route path="/statistics" element={<StatisticsPage />} />

                <Route path="/help" element={<HelpPage />} />

                <Route path="/companies" element={<Companies />} />
                <Route
                  path="/companies/:companyId/billing"
                  element={<CompanyBilling />}
                />
                <Route
                  path="/companies/:companyId/users/:userId"
                  element={<UserDetails />}
                />
                <Route
                  path="/companies/:companyId"
                  element={<CompanyDetails />}
                />

                <Route path="*" element={<NotFound />} />
              </Routes>
            </div>
          </main>
        </div>
      </BrowserRouter>
    </Context.Provider>
  );
};
