import { Breadcrumb } from "../components/Breadcrumb";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Company } from "../models/Company";
import { loadCompany } from "../api/Company";

export const CompanyBilling = () => {
  const [company, setCompany] = useState<Company>();

  const { companyId } = useParams();

  useEffect(() => {
    if (!companyId) {
      return;
    }

    loadCompany(companyId).then((loadedCompany) => {
      setCompany(loadedCompany);
    });
  }, [companyId]);

  return (
    <>
      <Breadcrumb
        items={[
          { text: "Companies", link: "/companies" },
          { text: company?.name || "", link: `/companies/${companyId}` },
          { text: "Billing", link: `/companies/${companyId}/billing` },
        ]}
      />
    </>
  );
};
