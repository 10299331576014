import { API } from "./API";
import { Device } from "../models/Device";
import { Page } from "./Page";
import { DeviceRegistrationToken } from "../models/DeviceRegistrationToken";
import { DeviceParticipation } from "../models/DeviceParticipation";

export const loadDevice = (id: number): Promise<Device> => {
  return API.get<Device>(`device/${id}`);
};

export const loadDevices = (): Promise<Device[]> => {
  return API.get<Device[]>("device");
};

export const loadDevicesByGame = (gameId: number): Promise<Device[]> => {
  return API.get<Device[]>(`game/${gameId}/devices`);
};

export const loadDevicesPage = (
  page?: number,
  size?: number,
  sort?: string,
  dir?: string,
  filter?: string
): Promise<Page<Device>> => {
  return API.get<Page<Device>>(
    "device/page?" +
      API.buildQueryParams({
        page,
        size,
        sort,
        dir,
        filter,
      })
  );
};

export const updateDevice = (device: Device): Promise<void> => {
  return API.put<void>(`device/${device.id}`, device);
};

export const createDeviceRegistrationToken = (
  companyId: number
): Promise<DeviceRegistrationToken> => {
  return API.get<DeviceRegistrationToken>(`device/token/${companyId}`);
};

export const loadDeviceParticipationsByGame = (
  gameId: number
): Promise<DeviceParticipation[]> => {
  return API.get<DeviceParticipation[]>(`game/${gameId}/device_participations`);
};

export const updateDeviceParticipation = (
  participationId: number,
  data: Partial<DeviceParticipation>
): Promise<void> => {
  return API.put<void>(`device_participation/${participationId}`, data);
};
