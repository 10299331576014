export const cropString = (
  str: string,
  length: number,
  postfix: string = "…"
) => {
  if (!str) {
    return "";
  }

  let cropped = str.substring(0, length);

  if (cropped.length < str.length) {
    cropped += postfix;
  }

  return cropped;
};
