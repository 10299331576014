export const formatIsoDateTime = (iso: string | undefined): string => {
  if (!iso) {
    return "";
  }

  return iso.replace("T", " ").split(".")[0];
};

export const formatIsoDate = (iso: string | undefined): string => {
  if (!iso) {
    return "";
  }

  return iso.split("T")[0];
};
