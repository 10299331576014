import React from "react";
import { Link } from "react-router-dom";
import { Device } from "../models/Device";
import { loadDevicesPage } from "../api/Device";
import { PaginatedTable } from "../components/PaginatedTable";
import { Breadcrumb } from "../components/Breadcrumb";

const TABLE_COLUMNS = [
  {
    id: "name",
    name: "Name",
    selector: (device: Device) => device.name,
    sortable: true,
  },
  {
    id: "phone_number",
    name: "Phone number",
    selector: (device: Device) => device.phone_number,
    sortable: true,
  },
  {
    id: "device_id",
    name: "Device ID",
    selector: (device: Device) => device.device_id,
    sortable: true,
  },
  {
    name: "View",
    button: true,
    sortable: false,
    cell: (device: Device) => (
      <Link to={`/devices/${device.id}`} className="btn btn-primary">
        <i className="fa-solid fa-file-pen"></i>
      </Link>
    ),
  },
];

export const DevicesPage = () => {
  return (
    <>
      <Breadcrumb items={[{ text: "Devices", link: "/devices" }]} />

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header pb-0">
              <div className="float-end">
                <Link to="/devices/new" className="btn btn-primary">
                  Register New Device{" "}
                  <i className="fa-solid fa-circle-plus"></i>
                </Link>
              </div>
              <h5 className="card-title mb-0">All Registered Devices</h5>
            </div>
            <div className="card-body">
              <PaginatedTable
                columns={TABLE_COLUMNS}
                loadPage={loadDevicesPage}
                filterable
                defaultSortField="name"
                defaultSortAsc
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
