import React from "react";
import { Link } from "react-router-dom";
import { Mission } from "../models/Mission";
import { loadMissionsPage } from "../api/Mission";
import { PaginatedTable } from "../components/PaginatedTable";
import { Breadcrumb } from "../components/Breadcrumb";
import { Map } from "../models/Map";
import { OwnershipIndicator } from "../components/OwnershipIndicator";

const TABLE_COLUMNS = [
  {
    id: "text",
    name: "Text",
    selector: (mission: Mission) => mission.text,
    sortable: true,
    wrap: true,
    grow: 0.7,
  },
  {
    id: "category",
    name: "Category",
    selector: (mission: Mission) => mission.category,
    sortable: true,
    grow: 0.1,
  },
  {
    id: "type",
    name: "Type",
    selector: (mission: Mission) => mission.type,
    sortable: true,
    grow: 0.1,
  },
  // {
  //   id: "blocks",
  //   name: "Blocks",
  //   selector: (mission: Mission) => mission.block_count || "",
  //   sortable: true,
  //   grow: 0.05,
  // },
  {
    name: "Owned By You",
    grow: 0.05,
    cell: (map: Map) => <OwnershipIndicator item={map} />,
  },
  {
    name: "View",
    button: true,
    sortable: false,
    grow: 0.05,
    cell: (mission: Mission) => (
      <Link to={`/missions/${mission.id}`} className="btn btn-primary">
        <i className="fa-solid fa-file-pen"></i>
      </Link>
    ),
  },
];

export const MissionsPage = () => {
  return (
    <>
      <Breadcrumb items={[{ text: "Missions", link: "/missions" }]} />
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header pb-0">
              <div className="float-end">
                <Link to="/missions/new" className="btn btn-primary">
                  New Mission <i className="fa-solid fa-circle-plus"></i>
                </Link>
              </div>
              <h5 className="card-title mb-0">Settings</h5>
            </div>
            <div className="card-body">
              <PaginatedTable
                columns={TABLE_COLUMNS}
                loadPage={loadMissionsPage}
                defaultSortField="id"
                filterable
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
