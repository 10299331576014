import { API } from "./API";
import { Block } from "../models/Block";
import { Page } from "./Page";

export const loadBlock = (id: string): Promise<Block> => {
  return API.get<Block>(`block/${id}`);
};

export const loadBlocks = (): Promise<Block[]> => {
  return API.get<Block[]>("block");
};

export const loadBlocksPage = (
  page?: number,
  size?: number,
  sort?: string,
  dir?: string,
  filter?: string
): Promise<Page<Block>> => {
  return API.get<Page<Block>>(
    "block/page?" +
      API.buildQueryParams({
        page,
        size,
        sort,
        dir,
        filter,
      })
  );
};

export const updateBlock = (block: Block): Promise<void> => {
  return API.put<void>(`block/${block.id}`, block);
};

export const createBlock = (block: Block): Promise<Block> => {
  return API.post<Block>("block", block);
};
