import { API } from "./API";
import { Map } from "../models/Map";
import { Page } from "./Page";

export const loadMap = (id: string): Promise<Map> => {
  return API.get<Map>(`map/${id}`);
};

export const loadMaps = (): Promise<Map[]> => {
  return API.get<Map[]>("map");
};

export const loadMapsPage = (
  page?: number,
  size?: number,
  sort?: string,
  dir?: string,
  filter?: string
): Promise<Page<Map>> => {
  return API.get<Page<Map>>(
    "map/page?" +
      API.buildQueryParams({
        page,
        size,
        sort,
        dir,
        filter,
      })
  );
};

export const updateMap = (map: Map): Promise<void> => {
  return API.put<void>(`map/${map.id}`, map);
};

export const createMap = (map: Map): Promise<Map> => {
  return API.post<Map>("map", map);
};
