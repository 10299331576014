import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Template } from "../models/Template";
import { Map } from "../models/Map";
import { loadMap, loadMapsPage } from "../api/Map";
import { createTemplate, loadTemplate, updateTemplate } from "../api/Template";
import { Block } from "../models/Block";
import { loadBlocksPage } from "../api/Block";
import { SearchableSelect } from "../components/SearchableSelect";
import { toast } from "react-toastify";
import { Breadcrumb } from "../components/Breadcrumb";
import { Context } from "../Context";

export const TemplateDetailsPage = () => {
  const [template, setTemplate] = useState<Template>();
  const [blocks, setBlocks] = useState<Block[]>([]);
  const [name, setName] = useState("");
  const [hardFailure, setHardFailure] = useState("0");
  const [enableCamera, setEnableCamera] = useState("1");
  const [saving, setSaving] = useState(false);
  const [missingEndBlock, setMissingEndBlock] = useState(false);
  const [currentMap, setCurrentMap] = useState<Map>();
  const [currentBlock, setCurrentBlock] = useState<Block>();
  const [owned, setOwned] = useState(false);
  const { id } = useParams();

  const navigate = useNavigate();
  const context = useContext(Context);

  useEffect(() => {
    if (!context?.user) {
      return;
    }

    setOwned(
      context.user.company_id == template?.company_id ||
        !!context.user.is_superadmin ||
        id === "new"
    );
  }, [context, template, id]);

  useEffect(() => {
    if (!id) {
      return;
    }

    if (id !== "new") {
      loadTemplate(id).then((res) => {
        setTemplate(res);
        setName(res.name);
        setHardFailure(res.hard_failure ? "1" : "0");
        setEnableCamera(res.enable_camera ? "1" : "0");

        if (res.block_order) {
          const sortedBlocks: Block[] = [];
          const availableBlocks = res.blocks || [];

          for (const blockId of res.block_order.split("|")) {
            const foundBlock = availableBlocks.find(
              (block) => block.id.toString() === blockId
            );

            if (foundBlock) {
              sortedBlocks.push(foundBlock);
            }
          }

          setBlocks(sortedBlocks);
        }

        if (res.map_id) {
          loadMap(res.map_id.toString()).then(setCurrentMap);
        }
      });
    }
  }, [id]);

  useEffect(() => {
    if (!blocks || blocks.length === 0) {
      return;
    }

    const last = blocks[blocks.length - 1];

    setMissingEndBlock(!last.final);
  }, [blocks]);

  const save = () => {
    if (saving || !currentMap || !context?.user) {
      return;
    }

    setSaving(true);

    const data = {
      name,
      map_id: currentMap.id,
      block_order: blocks.map((block) => block.id.toString()).join("|"),
      block_ids: blocks.map((block) => block.id),
      hard_failure: hardFailure === "1",
      enable_camera: enableCamera === "1",
    };

    if (id === "new") {
      createTemplate({
        ...data,
        id: 0,
        company_id: context.user.company_id,
        is_public: false,
      })
        .then((res) => {
          toast.success(`The template "${name}" was created.`);
          navigate(`/templates/${res.id}`);
        })
        .catch((error) => toast.error("Failed to create template."))
        .finally(() => {
          setSaving(false);
        });
    } else {
      if (!template) {
        return;
      }

      updateTemplate({
        ...template,
        ...data,
      })
        .then(() => toast.success(`The template "${name}" was saved.`))
        .catch((error) => toast.error("Failed to save template."))
        .finally(() => {
          setSaving(false);
        });
    }
  };

  const addBlock = () => {
    if (!currentBlock) {
      return;
    }

    setBlocks([...blocks, currentBlock]);
  };

  const removeBlock = (id: string) => {
    setBlocks(blocks.filter((block) => block.id.toString() !== id));
  };

  const moveBlock = (id: string, direction: number) => {
    const index = blocks.findIndex((block) => block.id.toString() === id);
    const block = blocks[index];

    if (
      (index === 0 && direction < 0) ||
      (index === blocks.length - 1 && direction > 0)
    ) {
      return;
    }

    const newBlocks = blocks.filter((block) => block.id.toString() !== id);
    newBlocks.splice(index + direction, 0, block);

    setBlocks(newBlocks);
  };

  return (
    <>
      <Breadcrumb
        items={[
          { text: "Templates", link: "/templates" },
          { text: name, link: `/templates/${template?.id}` },
        ]}
      />

      <div className="row">
        <div className="col-12 col-md-6">
          <div className="card">
            <div className="card-header pb-0">
              <h5 className="card-title mb-0">Settings</h5>
            </div>
            <div className="card-body">
              <div className="form-group">
                <label className="form-label">Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  disabled={!owned}
                  onChange={(evt) => setName(evt.target.value)}
                />
              </div>
              <div className="form-group mt-4">
                <label className="form-label">Map</label>
                <div className="row pe-3">
                  <div className="col-11">
                    {((!!template && !template.map_id) ||
                      currentMap ||
                      id === "new") && (
                      <SearchableSelect
                        labelField="name"
                        defaultValue={currentMap}
                        loadPage={loadMapsPage}
                        onChange={setCurrentMap}
                      />
                    )}
                  </div>
                  <div className="col-1">
                    {!!currentMap && (
                      <Link
                        to={`/maps/${currentMap.id}`}
                        target="_blank"
                        className="btn btn-primary"
                      >
                        <i className="fas fa-external-link-alt"></i>
                      </Link>
                    )}
                  </div>{" "}
                </div>
              </div>
              <div className="form-group mt-4">
                <label className="form-label">Hard failure</label>
                <select
                  className="form-select"
                  value={hardFailure}
                  disabled={!owned}
                  onChange={(evt) => setHardFailure(evt.target.value)}
                >
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </select>
              </div>
              <div className="form-group mt-4">
                <label className="form-label">Enable camera</label>
                <select
                  className="form-select"
                  value={enableCamera}
                  disabled={!owned}
                  onChange={(evt) => setEnableCamera(evt.target.value)}
                >
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </select>
              </div>
              {owned && (
                <button
                  type="button"
                  className="btn btn-primary mt-4"
                  disabled={saving}
                  onClick={save}
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="card">
            <div className="card-header pb-0">
              <h5 className="card-title mb-0">Blocks</h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-12 col-md-10">
                  <div className="form-group">
                    <SearchableSelect
                      labelField="name"
                      loadPage={loadBlocksPage}
                      onChange={setCurrentBlock}
                      getLabel={(block) => `[${block.type}] ${block.name}`}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-2">
                  {owned && (
                    <button
                      type="button"
                      className="btn btn-primary"
                      disabled={!currentBlock}
                      onClick={addBlock}
                    >
                      Add
                    </button>
                  )}
                </div>
              </div>
              <br />
              {/*{missingEndBlock && (*/}
              {/*  <div className="alert alert-danger">*/}
              {/*    <i className="fa-solid fa-triangle-exclamation"></i> The last*/}
              {/*    block must be a block marked [END BLOCK].*/}
              {/*  </div>*/}
              {/*)}*/}
              <br />
              <table className="table table-sm table-bordered table-striped">
                <tbody>
                  {blocks.map((block, index) => (
                    <tr key={block.id}>
                      <td style={{ width: "10%" }}>{index + 1}</td>
                      <td style={{ width: "70%" }}>
                        [{block.type}] {block.name}{" "}
                        {!!block.final && "[END BLOCK]"}
                      </td>
                      {owned && (
                        <td className="p-1" style={{ width: "20%" }}>
                          <button
                            type="button"
                            className="btn btn-sm btn-primary"
                            onClick={() => moveBlock(block.id.toString(), -1)}
                          >
                            <i className="fa-solid fa-arrow-up"></i>
                          </button>
                          <button
                            type="button"
                            className="btn btn-sm btn-primary mx-2"
                            onClick={() => moveBlock(block.id.toString(), 1)}
                          >
                            <i className="fa-solid fa-arrow-down"></i>
                          </button>
                          <button
                            type="button"
                            className="btn btn-sm btn-primary"
                            onClick={() => removeBlock(block.id.toString())}
                          >
                            <i className="fa-solid fa-xmark"></i>
                          </button>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
