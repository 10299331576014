import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { User } from "../models/User";
import { createUser, loadUser, updateUser } from "../api/User";
import { toast } from "react-toastify";
import { Company } from "../models/Company";
import { loadCompany } from "../api/Company";
import { Breadcrumb } from "../components/Breadcrumb";

export const UserDetails = () => {
  const [user, setUser] = useState<User>();
  const [company, setCompany] = useState<Company>();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [saving, setSaving] = useState(false);

  const { companyId, userId } = useParams();
  const navigate = useNavigate();

  const save = () => {
    if (saving || !companyId) {
      return;
    }

    setSaving(true);

    const data = {
      name,
      email,
      company_id: parseInt(companyId, 10),
      password: newPassword || undefined,
    };

    if (userId === "new") {
      createUser({
        ...data,
        id: 0,
      })
        .then((res) => {
          toast.success(`The user "${name}" was created.`);
          navigate(`/companies/${companyId}/users/${res.id}`);
        })
        .catch((error) => {
          toast.error("Failed to create user.");
        })
        .finally(() => setSaving(false));
    } else {
      if (!user) {
        return;
      }

      updateUser({
        ...user,
        ...data,
      })
        .then(() => toast.success(`The user "${name}" was saved."`))
        .catch((error) => toast.error("Failed to save user."))
        .finally(() => setSaving(false));
    }
  };

  useEffect(() => {
    if (!companyId || !userId) {
      return;
    }

    if (userId === "new") {
      loadCompany(companyId).then(setCompany);
    } else {
      loadUser(userId).then((loadedUser) => {
        setUser(loadedUser);
        setName(loadedUser.name);
        setEmail(loadedUser.email);
      });
    }
  }, [companyId, userId]);

  return (
    <>
      <Breadcrumb
        items={[
          { text: "Companies", link: "/companies" },
          {
            text: user?.company?.name || company?.name || "",
            link: `/companies/${companyId}`,
          },
          {
            text: user?.name || "",
            link: `/companies/${companyId}/users/${user?.id}`,
          },
        ]}
      />
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="form-group">
            <label className="form-label">Company</label>
            <input
              type="text"
              className="form-control"
              value={user?.company?.name || company?.name}
              disabled
            />
          </div>
          <div className="form-group mt-4">
            <label className="form-label">Name</label>
            <input
              type="text"
              className="form-control"
              value={name}
              onChange={(evt) => setName(evt.target.value)}
            />
          </div>
          <div className="form-group mt-4">
            <label className="form-label">Email</label>
            <input
              type="text"
              className="form-control"
              value={email}
              onChange={(evt) => setEmail(evt.target.value)}
            />
          </div>
          <div className="form-group mt-4">
            <label className="form-label">
              Password {userId !== "new" ? "(leave blank to keep current)" : ""}
            </label>
            <input
              type="password"
              className="form-control"
              value={newPassword}
              onChange={(evt) => setNewPassword(evt.target.value)}
            />
          </div>
          <button
            type="button"
            className="btn btn-primary mt-4"
            disabled={saving}
            onClick={save}
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
};
