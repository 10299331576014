import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Block } from "../models/Block";
import { createBlock, loadBlock, updateBlock } from "../api/Block";
import { Mission } from "../models/Mission";
import { SearchableSelect } from "../components/SearchableSelect";
import { loadMissionsPage } from "../api/Mission";
import { toast } from "react-toastify";
import { Breadcrumb } from "../components/Breadcrumb";
import { Context } from "../Context";

export const BlockDetailsPage = () => {
  const [block, setBlock] = useState<Block>();
  const [missions, setMissions] = useState<Mission[]>([]);
  const [name, setName] = useState("");
  const [type, setType] = useState("RANDOM");
  const [timeLimit, setTimeLimit] = useState("0");
  const [saving, setSaving] = useState(false);
  const [currentMission, setCurrentMission] = useState<Mission>();
  const [owned, setOwned] = useState(false);
  const { id } = useParams();

  const navigate = useNavigate();
  const context = useContext(Context);

  useEffect(() => {
    if (!context?.user) {
      return;
    }

    setOwned(
      context.user.company_id == block?.company_id ||
        !!context.user.is_superadmin ||
        id === "new"
    );
  }, [context, block, id]);

  useEffect(() => {
    if (!id) {
      return;
    }

    if (id !== "new") {
      loadBlock(id).then((res) => {
        setBlock(res);
        setMissions(res.missions || []);
        setName(res.name);
        setType(res.type);
        setTimeLimit(res.time_limit?.toString() || "0");
      });
    }
  }, [id]);

  const save = () => {
    if (saving || !context?.user) {
      return;
    }

    const data = {
      name,
      type,
      time_limit: parseInt(timeLimit, 10),
      mission_ids: missions.map((mission) => mission.id),
    };

    if (id === "new") {
      createBlock({
        ...data,
        id: 0,
        company_id: context.user.company_id,
        is_public: false,
      })
        .then((res) => {
          toast.success(`The block "${name}" was created.`);
          navigate(`/blocks/${res.id}`);
        })
        .catch((error) => toast.error("Failed to create block."))
        .finally(() => {
          setSaving(false);
        });
    } else {
      if (!block) {
        return;
      }

      const updateData = {
        ...block,
        ...data,
      };

      delete updateData["missions"];

      updateBlock(updateData)
        .then(() => toast.success(`The block "${name}" was saved.`))
        .catch((error) => toast.error("Failed to save block."))
        .finally(() => {
          setSaving(false);
        });
    }
  };

  const addMission = () => {
    if (!currentMission) {
      return;
    }

    setMissions([...missions, currentMission]);
  };

  const removeMission = (id: string) => {
    setMissions(missions.filter((mission) => mission.id.toString() !== id));
  };

  const moveMission = (id: string, direction: number) => {
    const index = missions.findIndex((mission) => mission.id.toString() === id);
    const mission = missions[index];

    if (
      (index === 0 && direction < 0) ||
      (index === missions.length - 1 && direction > 0)
    ) {
      return;
    }

    const newMissions = missions.filter(
      (mission) => mission.id.toString() !== id
    );
    newMissions.splice(index + direction, 0, mission);

    setMissions(newMissions);
  };

  return (
    <>
      <Breadcrumb
        items={[
          { text: "Blocks", link: "/blocks" },
          { text: name, link: `/blocks/${block?.id}` },
        ]}
      />
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="card">
            <div className="card-header pb-0">
              <h5 className="card-title mb-0">Settings</h5>
            </div>
            <div className="card-body">
              <div className="form-group">
                <label className="form-label">Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  disabled={!owned}
                  onChange={(evt) => setName(evt.target.value)}
                />
              </div>
              <div className="form-group mt-4">
                <label className="form-label">Mission order</label>
                <select
                  className="form-select"
                  value={type}
                  disabled={!owned}
                  onChange={(evt) => setType(evt.target.value)}
                >
                  <option value="RANDOM">Random</option>
                  <option value="ITERATIVE">Iterative</option>
                  <option value="STACK">Stack</option>
                </select>
              </div>
              <div className="form-group mt-4">
                <label className="form-label">Time limit (seconds)</label>
                <input
                  type="number"
                  className="form-control"
                  max={0}
                  value={timeLimit}
                  disabled={!owned}
                  onChange={(evt) => setTimeLimit(evt.target.value)}
                />
              </div>
              {owned && (
                <button
                  type="button"
                  className="btn btn-primary mt-4"
                  disabled={saving}
                  onClick={save}
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="card">
            <div className="card-header pb-0">
              <h5 className="card-title mb-0">Missions</h5>
            </div>
            <div className="card-body">
              <div className="form-group">
                <div className="row">
                  <div className="col-12 col-md-10">
                    <div className="form-group">
                      <SearchableSelect
                        labelField="text"
                        loadPage={loadMissionsPage}
                        onChange={setCurrentMission}
                        defaultValue={currentMission}
                        getLabel={(mission) =>
                          `[${mission.type}][${mission.category}] ${mission.text}`
                        }
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-2">
                    {owned && (
                      <button
                        type="button"
                        className="btn btn-primary"
                        disabled={!currentMission}
                        onClick={addMission}
                      >
                        Add
                      </button>
                    )}
                  </div>
                </div>
                <br />
                <br />
                <table className="table table-sm table-bordered table-striped">
                  <tbody>
                    {missions.map((mission, index) => (
                      <tr key={mission.id}>
                        <td style={{ width: "10%" }}>{index + 1}</td>
                        <td style={{ width: "70%" }}>
                          [{mission.type}][{mission.category}] {mission.text}
                        </td>
                        <td className="p-1" style={{ width: "20%" }}>
                          <button
                            type="button"
                            className="btn btn-sm btn-primary"
                            onClick={() =>
                              moveMission(mission.id.toString(), -1)
                            }
                          >
                            <i className="fa-solid fa-arrow-up"></i>
                          </button>
                          <button
                            type="button"
                            className="btn btn-sm btn-primary mx-2"
                            onClick={() =>
                              moveMission(mission.id.toString(), 1)
                            }
                          >
                            <i className="fa-solid fa-arrow-down"></i>
                          </button>
                          <button
                            type="button"
                            className="btn btn-sm btn-primary"
                            onClick={() => removeMission(mission.id.toString())}
                          >
                            <i className="fa-solid fa-xmark"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
