import React, { useContext, useEffect, useState } from "react";
import { Breadcrumb } from "../components/Breadcrumb";
import { Link } from "react-router-dom";
import { DeviceRegistrationToken } from "../models/DeviceRegistrationToken";
import { createDeviceRegistrationToken } from "../api/Device";
import QRCode from "react-qr-code";
import { Context } from "../Context";

export const DeviceRegister = () => {
  const [token, setToken] = useState<DeviceRegistrationToken>();
  const context = useContext(Context);

  useEffect(() => {
    if (!context?.user) {
      return;
    }

    createDeviceRegistrationToken(context.user.company_id).then(setToken);
  }, [context]);

  return (
    <>
      <Breadcrumb
        items={[
          { text: "Devices", link: "/devices" },
          { text: "Register New Device", link: "/devices/new" },
        ]}
      />

      <div className="row">
        <div className="col-12 col-md-6">
          <div className="card">
            <div className="card-header pb-0">
              <div className="float-end">
                <Link to="/devices/new" className="btn btn-primary">
                  Register Another Device{" "}
                  <i className="fa-solid fa-circle-plus"></i>
                </Link>
              </div>
              <h5 className="card-title mb-0">Register New Device</h5>
            </div>
            <div className="card-body">
              <ol>
                <li>Open CG Admin.</li>
                <li>Scan the QR-code.</li>
                <li>Follow the instructions in CG Admin.</li>
              </ol>
              <span className="text-muted">
                If you want to register multiple devices, you need to click
                "Register Another Device" between each.
              </span>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="card">
            <div className="card-header pb-0">
              <h5 className="card-title mb-0">QR Code</h5>
            </div>
            <div className="card-body">
              {!!token && (
                <QRCode
                  value={JSON.stringify({
                    registrationToken: token.token,
                    companyId: token.company_id,
                  })}
                  level="M"
                  size={256}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
