import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Game } from "../models/Game";
import { loadGame } from "../api/Game";
import { CircleMarker, MapContainer, TileLayer, Tooltip } from "react-leaflet";

import "leaflet/dist/leaflet.css";
import { Device } from "../models/Device";
import {
  loadDeviceParticipationsByGame,
  loadDevicesByGame,
  updateDeviceParticipation,
} from "../api/Device";
import { loadAnswers } from "../api/Answer";
import { Photo } from "../models/Photo";
import {
  approvePhoto,
  getPhotoUrl,
  loadUncheckedPhotoList,
} from "../api/Photo";
import { listSMSByGameAndDevice, sendSMSToDevice } from "../api/SMS";
import { Template } from "../models/Template";
import { Map } from "../models/Map";
import { loadTemplate, loadTemplateMissions } from "../api/Template";
import { loadMap } from "../api/Map";
import { Breadcrumb } from "../components/Breadcrumb";
import { DeviceParticipation } from "../models/DeviceParticipation";
import { cropString } from "../utils/StringUtils";
import { Variable } from "../models/Variable";
import { loadVariablesByGame } from "../api/Variable";
import { toast } from "react-toastify";
import { loadDeviceStatusesByGame } from "../api/DeviceStatus";
import { DeviceStatus } from "../models/DeviceStatus";

const DEVICE_UPDATE_INTERVAL = 10 * 1000;
const DATA_UPDATE_INTERVAL = 20 * 1000;
const ANSWER_POLL_INTERVAL = 20 * 1000;
const MISSION_TEXT_MAX_LENGTH = 100;

interface FollowListItem {
  icon: string;
  id: string;
  text: string;
  answer: string;
  time: string;
  className: string;
}

enum Tab {
  Teams,
  Map,
  Photos,
  SMS,
  Variables,
}

enum VariablesGrouping {
  ByVariable,
  ByTeam,
}

let loadingDevices = false;
let loadingData = false;
let loadingAnswers = false;

export const GameFollowPage = () => {
  const [tab, setTab] = useState<Tab>(Tab.Teams);
  const [variablesGrouping, setVariablesGrouping] = useState<VariablesGrouping>(
    VariablesGrouping.ByVariable
  );
  const [game, setGame] = useState<Game>();
  const [template, setTemplate] = useState<Template>();
  const [map, setMap] = useState<Map>();
  const [mapHiddenParticipations, setMapHiddenParticipations] = useState<
    string[]
  >([]);
  const [lastUpdate, setLastUpdate] = useState<Date | undefined>();
  const [statuses, setStatuses] = useState<DeviceStatus[]>([]);
  const [devices, setDevices] = useState<Device[]>([]);
  const [deviceParticipations, setDeviceParticipations] = useState<
    DeviceParticipation[]
  >([]);
  const [variables, setVariables] = useState<Variable[]>([]);
  const [sms, setSms] = useState("");
  const [uncheckedPhotos, setUncheckedPhotos] = useState<Photo[]>([]);
  const [showAnswers, setShowAnswers] = useState(false);
  const [activeDeviceId, setActiveDeviceId] = useState<number>();
  const [activeItems, setActiveItems] = useState<FollowListItem[]>([]);
  const [missionTexts, setMissionTexts] = useState<{ [key: number]: string }>(
    {}
  );
  const [mapMissionTexts, setMapMissionTexts] = useState<{
    [key: number]: string;
  }>({});
  const [missionShortTexts, setMissionShortTexts] = useState<{
    [key: number]: string;
  }>({});
  const [mapMissionShortTexts, setMapMissionShortTexts] = useState<{
    [key: number]: string;
  }>({});
  const [modalPhotoUrl, setModalPhotoUrl] = useState("");
  const [showMapMissions, setShowMapMissions] = useState(true);
  const answerPollInterval = useRef<NodeJS.Timer>();
  const updatePollInterval = useRef<NodeJS.Timer>();
  const deviceUpdateInterval = useRef<NodeJS.Timer>();
  const { id } = useParams();

  const formatDate = (date: string) => {
    return new Date(date).toLocaleString("sv-SE", {
      timeZone: "Europe/Stockholm",
    });
  };

  const sendSms = () => {
    if (!id || !sms.trim()) {
      return;
    }

    Promise.all(
      devices.map((device) => sendSMSToDevice(id, device.id, sms.trim()))
    )
      .then(() => {
        setSms("");
        toast.success("SMS sent.");
      })
      .catch((e) => {
        console.log("######", e);
        toast.error("Failed to send SMS.");
      });
  };

  useEffect(() => {
    if (!id) {
      return;
    }

    loadGame(id).then((loadedGame) => {
      setGame(loadedGame);

      loadTemplateMissions(loadedGame.template_id.toString()).then(
        (loadedMissions) => {
          const texts: { [key: number]: string } = {};
          const shortTexts: { [key: number]: string } = {};

          for (const mission of loadedMissions) {
            texts[mission.id] = mission.text;
            shortTexts[mission.id] = mission.short_text;
          }

          setMissionTexts(texts);
          setMissionShortTexts(shortTexts);
        }
      );

      loadTemplate(loadedGame.template_id.toString()).then((loadedTemplate) => {
        setTemplate(loadedTemplate);

        if (loadedTemplate.map_id) {
          loadMap(loadedTemplate.map_id.toString()).then((loadedMap) => {
            setMap(loadedMap);

            const texts: { [key: number]: string } = {};
            const shortTexts: { [key: number]: string } = {};

            for (const mission of loadedMap.missions) {
              texts[mission.id] = mission.text;
              shortTexts[mission.id] = mission.short_text;
            }

            setMapMissionTexts(texts);
            setMapMissionShortTexts(shortTexts);
          });
        }
      });
    });
  }, [id]);

  useEffect(() => {
    if (!id) {
      return;
    }

    const loadGameDevices = async () => {
      await Promise.all([
        loadDeviceParticipationsByGame(parseInt(id, 10)).then(
          setDeviceParticipations
        ),

        loadDevicesByGame(parseInt(id, 10)).then(setDevices),

        loadDeviceStatusesByGame(parseInt(id, 10)).then(setStatuses),
      ]);
    };

    loadGameDevices();

    if (deviceUpdateInterval.current) {
      clearInterval(deviceUpdateInterval.current);
    }

    deviceUpdateInterval.current = setInterval(async () => {
      if (loadingDevices) {
        return;
      }

      loadingDevices = true;
      await loadGameDevices();
      loadingDevices = false;
    }, DEVICE_UPDATE_INTERVAL);
  }, [id]);

  useEffect(() => {
    const loadData = async () => {
      if (!id) {
        return;
      }

      await Promise.all([
        loadVariablesByGame(parseInt(id, 10)).then(setVariables),

        loadUncheckedPhotoList(id).then(setUncheckedPhotos),
      ]);

      setLastUpdate(new Date());
    };

    setTimeout(() => {
      loadData();
    }, 3000);
    //loadData();

    if (updatePollInterval.current) {
      clearInterval(updatePollInterval.current);
    }

    updatePollInterval.current = setInterval(async () => {
      if (loadingData) {
        return;
      }

      loadingData = true;
      await loadData();
      loadingData = false;
    }, DATA_UPDATE_INTERVAL);
  }, []);

  useEffect(() => {
    const loadAllAnswers = async () => {
      if (!id || !activeDeviceId) {
        return;
      }

      const missionAnswers = await loadAnswers(id, activeDeviceId);
      const missionAnswerItems: FollowListItem[] = missionAnswers.map(
        (answer) => ({
          icon: answer.is_map
            ? "fa-solid fa-map-location-dot"
            : "fa-regular fa-circle-question",
          id: answer.mission_id,
          text: getMissionText(
            parseInt(answer.mission_id, 10),
            !!answer.is_map
          ),
          answer: `${answer.answer} [${answer.score > 0 ? "+" : ""}${
            answer.score
          }p]`,
          time: answer.created_at || "",
          className: answer.correct ? "success" : "danger",
        })
      );

      const sms = await listSMSByGameAndDevice(id, activeDeviceId);
      const smsItems: FollowListItem[] = sms.map((sms) => ({
        icon: "fa-regular fa-envelope",
        id: "SMS",
        text: sms.text,
        answer: sms.seen ? "Seen" : "Not seen",
        time: sms.created_at || "",
        className: sms.seen ? "success" : "info",
      }));

      const items = [...missionAnswerItems, ...smsItems].sort((a, b) =>
        a.time < b.time ? 1 : -1
      );

      setActiveItems(items);
    };

    if (answerPollInterval.current) {
      clearInterval(answerPollInterval.current);
    }

    if (!activeDeviceId) {
      return;
    }

    loadAllAnswers();

    answerPollInterval.current = setInterval(async () => {
      if (loadingAnswers) {
        return;
      }

      loadingAnswers = true;
      await loadAllAnswers();
      loadingAnswers = false;
    }, ANSWER_POLL_INTERVAL);
  }, [activeDeviceId]);

  const renderAge = (updated: string | undefined) => {
    if (!updated) {
      return null;
    }

    const updatedTimestamp = new Date(updated).getTime();
    const ageMs = Date.now() - updatedTimestamp;
    const ageMinutes = Math.min(Math.floor(ageMs / 1000 / 60), 60);
    const ageStr = `${ageMinutes >= 60 ? ">" : ""}${ageMinutes}m`;

    let ageClass = "success";
    if (ageMinutes > 5) ageClass = "warning";
    if (ageMinutes > 10) ageClass = "danger";

    return (
      <span className={`px-2 py-1 float-end badge bg-${ageClass}`}>
        {ageStr}
      </span>
    );
  };

  const getDeviceDisplayName = (deviceId: number) => {
    const device = devices.find((device) => device.id === deviceId);

    if (device) {
      const cleanPhoneNumber = device.phone_number.replace(/\D/g, "");
      return `${device.name} (${cleanPhoneNumber || "NO PHONENUMBER"})`;
    }

    return deviceId || "[UNKNOWN]";
  };

  const getDeviceDisplayNameShort = (id: number | undefined) => {
    const device = devices.find((device) => device.id === id);

    if (device) {
      return device.name;
    }

    return "[UNKNOWN]";
  };

  const getMissionText = (missionId: number, isMap: boolean) => {
    return (isMap ? mapMissionTexts : missionTexts)[missionId] || "";
  };

  const getMissionShortText = (missionId: number, isMap: boolean) => {
    if (isMap) {
      return mapMissionShortTexts[missionId] || mapMissionTexts[missionId];
    }

    return missionShortTexts[missionId] || missionTexts[missionId];
  };

  const approveImage = (photoId: number, approved: boolean) => {
    setUncheckedPhotos(uncheckedPhotos.filter((photo) => photo.id !== photoId));

    approvePhoto(photoId, approved).then(() => {});
  };

  const promptBonusScore = (participation: DeviceParticipation) => {
    const scoreStr = prompt(
      "Enter bonus score to add. Can be negative to remove points.",
      "0"
    );

    const score = parseInt(scoreStr || "", 10);

    if (isNaN(score) || !id) {
      return;
    }

    updateDeviceParticipation(participation.id, {
      bonus_score: participation.bonus_score + score,
    });
  };

  const getScore = (participation: DeviceParticipation | undefined | null) => {
    if (!participation) {
      return 0;
    }

    return (participation.score || 0) + (participation.bonus_score || 0);
  };

  const getParticipationByDeviceId = (deviceId: string) => {
    const device = devices.find((device) => device.device_id === deviceId);

    if (!device) {
      return null;
    }

    return deviceParticipations.find(
      (participation) => participation.device_id === device.id
    );
  };

  const getStatusByParticipation = (participation: DeviceParticipation) => {
    const device = devices.find(
      (device) => device.device_id === participation.device_id.toString()
    );

    if (!device) {
      return null;
    }

    return statuses.find((status) => status.device_id === device.device_id);
  };

  const renderTabs = () => {
    return (
      <div className="col-12 col-md-6">
        <div className="btn-group">
          <button
            type="button"
            onClick={() => setTab(Tab.Teams)}
            className={`btn btn-lg ${
              tab === Tab.Teams ? "btn-primary" : "btn-outline-primary"
            }`}
          >
            Teams
          </button>
          <button
            type="button"
            onClick={() => setTab(Tab.Map)}
            className={`btn btn-lg ${
              tab === Tab.Map ? "btn-primary" : "btn-outline-primary"
            }`}
          >
            Map
          </button>
          <button
            type="button"
            onClick={() => setTab(Tab.Photos)}
            className={`btn btn-lg ${
              tab === Tab.Photos ? "btn-primary" : "btn-outline-primary"
            }`}
          >
            Photos
          </button>
          <button
            type="button"
            onClick={() => setTab(Tab.SMS)}
            className={`btn btn-lg ${
              tab === Tab.SMS ? "btn-primary" : "btn-outline-primary"
            }`}
          >
            SMS
          </button>
          <button
            type="button"
            onClick={() => setTab(Tab.Variables)}
            className={`btn btn-lg ${
              tab === Tab.Variables ? "btn-primary" : "btn-outline-primary"
            }`}
          >
            Variables
          </button>
        </div>
      </div>
    );
  };

  const renderTeams = () => {
    return (
      <div className="row">
        <div className="col-12">
          <table className="table table-striped table-hover table-bordered">
            <thead>
              <tr>
                <th>Team</th>
                <th>Score</th>
                <th>Current Mission</th>
                <th>Device</th>
                <th>Show</th>
              </tr>
            </thead>
            <tbody>
              {deviceParticipations
                ?.sort((a, b) => {
                  // TODO: Change to sortable table
                  const a_score = getScore(a);
                  const b_score = getScore(b);

                  return b_score - a_score;
                })
                .map((participation) => {
                  const status = getStatusByParticipation(participation);

                  return (
                    <tr key={participation.id}>
                      <td>{participation?.team_name}</td>
                      <td>
                        {getScore(participation)}
                        <button
                          type="button"
                          className="btn btn-primary btn-sm float-end"
                          style={{ marginTop: -5 }}
                          onClick={() => promptBonusScore(participation)}
                        >
                          +
                        </button>
                      </td>
                      <td>
                        {participation?.current_mission_id
                          ? cropString(
                              getMissionShortText(
                                participation.current_mission_id,
                                false
                              ),
                              MISSION_TEXT_MAX_LENGTH
                            )
                          : ""}
                      </td>
                      <td>
                        {getDeviceDisplayName(participation.device_id)}{" "}
                        {renderAge(status?.updated_at || status?.created_at)}
                      </td>
                      <td className="p-2">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => {
                            const device = devices.find(
                              (device) => device.id === participation.device_id
                            );

                            if (!device) {
                              return;
                            }

                            if (device.id === activeDeviceId) {
                              setActiveDeviceId(undefined);
                              setShowAnswers(false);
                              return;
                            }

                            setActiveDeviceId(device.id);
                            setShowAnswers(true);
                          }}
                        >
                          <i className="fa-solid fa-bars-staggered"></i>
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const renderMap = () => {
    return (
      <div className="row">
        <div className="col-12 px-0">
          {!!map && (
            <MapContainer
              center={[map.home_longitude, map.home_latitude]}
              zoom={14}
              style={{ height: 600 }}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {showMapMissions &&
                map.missions.map((mission) => {
                  return (
                    <CircleMarker
                      key={mission.id}
                      center={[
                        parseFloat(mission.longitude),
                        parseFloat(mission.latitude),
                      ]}
                      pathOptions={{
                        fillColor: "#bb2d3b",
                        fill: true,
                        fillOpacity: 1.0,
                        stroke: true,
                        color: "#ffffff",
                        weight: 2,
                      }}
                      radius={7}
                    >
                      <Tooltip
                        direction="top"
                        offset={[0, -3]}
                        opacity={0.8}
                        permanent
                      >
                        <p className="text-center m-0">
                          <strong>{mission.marker_text}</strong>
                          <br />
                          <small>
                            {(mission.short_text || mission.text).substring(
                              0,
                              50
                            )}
                          </small>
                        </p>
                      </Tooltip>
                    </CircleMarker>
                  );
                })}
              {statuses
                ?.filter((status) => !!status.latitude && !!status.longitude)
                .map((status) => {
                  const participation = getParticipationByDeviceId(
                    status.device_id
                  );

                  if (
                    !participation ||
                    mapHiddenParticipations.includes(
                      participation.id.toString()
                    )
                  ) {
                    return null;
                  }

                  return (
                    <CircleMarker
                      key={status.id}
                      center={[status.latitude, status.longitude]}
                      pathOptions={{
                        fillColor: "#326abc",
                        fill: true,
                        fillOpacity: 1.0,
                        stroke: true,
                        color: "#ffffff",
                        weight: 2,
                      }}
                      radius={7}
                    >
                      <Tooltip
                        direction="top"
                        offset={[0, -3]}
                        opacity={0.8}
                        permanent
                      >
                        <p className="text-center m-0">
                          <strong>{participation?.team_name || ""}</strong>
                          <br />
                          <small>
                            {
                              devices.find(
                                (device) =>
                                  device.id === participation?.device_id
                              )?.name
                            }
                          </small>
                        </p>
                      </Tooltip>
                    </CircleMarker>
                  );
                })}
            </MapContainer>
          )}
        </div>
        <div className="card mt-3">
          <div className="card-header pb-0">
            <h5 className="card-title mb-0">Missions</h5>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-12 mt-3 mb-3">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="map-check-showmissions"
                    checked={showMapMissions}
                    onChange={(evt) => {
                      setShowMapMissions(!showMapMissions);
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="map-check-showmissions"
                  >
                    <strong>Show missions</strong>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card mt-3">
          <div className="card-header pb-0">
            <h5 className="card-title mb-0">Teams</h5>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-12 mt-3 mb-3">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="map-check-showall"
                    checked={mapHiddenParticipations.length === 0}
                    onChange={(evt) => {
                      if (mapHiddenParticipations.length > 0) {
                        setMapHiddenParticipations([]);
                      } else {
                        setMapHiddenParticipations(
                          deviceParticipations.map((participation) =>
                            participation.id.toString()
                          )
                        );
                      }
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="map-check-showall"
                  >
                    <strong>Show all</strong>
                  </label>
                </div>
              </div>
              {deviceParticipations.map((participation) => {
                return (
                  <div className="col-3 mt-1" key={participation.id.toString()}>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={`map-check-${participation.id}`}
                        checked={
                          !mapHiddenParticipations.includes(
                            participation.id.toString()
                          )
                        }
                        onChange={(evt) => {
                          if (
                            mapHiddenParticipations.includes(
                              participation.id.toString()
                            )
                          ) {
                            setMapHiddenParticipations(
                              mapHiddenParticipations.filter(
                                (p) => p != participation.id.toString()
                              )
                            );
                          } else {
                            setMapHiddenParticipations([
                              ...mapHiddenParticipations,
                              participation.id.toString(),
                            ]);
                          }
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`map-check-${participation.id}`}
                      >
                        {participation.team_name}
                      </label>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderSMS = () => {
    return (
      <>
        <div className="row mt-3">
          <div className="col-md-6">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <textarea
                      className="w-100"
                      rows={5}
                      maxLength={150}
                      onChange={(evt) => setSms(evt.target.value)}
                      value={sms}
                    />
                  </div>
                  <div className="col-12 mt-3">
                    <div className="col-md-6">{sms.length}/150</div>
                  </div>
                  <div className="col-12 mt-3">
                    <button
                      type="button"
                      className="btn btn-primary"
                      disabled={!sms.trim()}
                      onClick={() => sendSms()}
                    >
                      Send SMS
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderPhotos = () => {
    return (
      <div className="row mt-3 mb-5">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              Photos to check ({uncheckedPhotos.length})
            </div>
            <div className="card-body">
              <table className="table table-striped table-hover table-bordered">
                <tbody>
                  {uncheckedPhotos.slice(0, 10).map((photo) => (
                    <tr key={photo.id.toString()}>
                      <td style={{ width: "33vw" }}>
                        <div
                          className="bg-secondary"
                          style={{ width: "32vw", height: "18vw" }}
                        >
                          <img
                            src={getPhotoUrl(id!, photo.file_name)}
                            className="object-fit-contain w-100 h-100"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              setModalPhotoUrl(
                                getPhotoUrl(id!, photo.file_name)
                              )
                            }
                          />
                        </div>
                      </td>
                      <td>{getMissionShortText(photo.mission_id, false)}</td>
                      <td style={{ minWidth: "20%" }}>
                        <div className="d-grid gap-2">
                          <button
                            type="button"
                            className="btn btn-success py-6"
                            onClick={() => approveImage(photo.id, true)}
                          >
                            Approve
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger py-6"
                            onClick={() => approveImage(photo.id, false)}
                          >
                            Reject
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderPhotoModal = () => {
    if (!modalPhotoUrl) {
      return null;
    }

    return (
      <div
        className="position-fixed w-100 h-100 top-0 start-0 p-5"
        style={{
          zIndex: 9999,
          backgroundColor: "#000000aa",
          cursor: "pointer",
        }}
        onClick={() => setModalPhotoUrl("")}
      >
        <div className="bg-secondary w-100 h-100">
          <img src={modalPhotoUrl} className="w-100 h-100 object-fit-contain" />
        </div>
      </div>
    );
  };

  const renderVariables = () => (
    <>
      <div className="btn-group">
        <button
          type="button"
          onClick={() => setVariablesGrouping(VariablesGrouping.ByVariable)}
          className={`btn btn-lg ${
            variablesGrouping === VariablesGrouping.ByVariable
              ? "btn-secondary"
              : "btn-outline-secondary"
          }`}
        >
          Group by Variable
        </button>
        <button
          type="button"
          onClick={() => setVariablesGrouping(VariablesGrouping.ByTeam)}
          className={`btn btn-lg ${
            variablesGrouping === VariablesGrouping.ByTeam
              ? "btn-secondary"
              : "btn-outline-secondary"
          }`}
        >
          Group by Team
        </button>
      </div>
      <div className="row mt-3">
        <div className="col-12">
          <table className="table table-striped table-hover table-bordered">
            <thead>
              {variablesGrouping === VariablesGrouping.ByTeam && (
                <tr>
                  <th>Team</th>
                  <th>Variables</th>
                </tr>
              )}
              {variablesGrouping === VariablesGrouping.ByVariable && (
                <tr>
                  <th>Variable</th>
                  <th>Teams</th>
                </tr>
              )}
            </thead>
            <tbody>
              {variablesGrouping === VariablesGrouping.ByTeam &&
                deviceParticipations.map((participation) => (
                  <tr>
                    <td>{participation.team_name}</td>
                    <td>
                      <table className="table table-bordered table-sm mb-0">
                        <tbody>
                          {variables
                            .filter(
                              (variable) =>
                                variable.device_id === participation.device_id
                            )
                            .map((variable) => (
                              <tr>
                                <td className="w-25">
                                  <strong>{variable.key}</strong>
                                </td>
                                <td>{variable.value}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                ))}
              {variablesGrouping === VariablesGrouping.ByVariable &&
                variables
                  .map((variable) => variable.key)
                  .filter((val, i, acc) => acc.indexOf(val) === i)
                  .map((variableKey) => (
                    <tr>
                      <td>{variableKey}</td>
                      <td>
                        <table className="table table-bordered table-sm mb-0">
                          <tbody>
                            {deviceParticipations.map((participation) => (
                              <tr>
                                <td className="w-25">
                                  <strong>{participation.team_name}</strong>
                                </td>
                                <td>
                                  {
                                    variables.find(
                                      (variable) =>
                                        variable.device_id ===
                                          participation.device_id &&
                                        variable.key === variableKey
                                    )?.value
                                  }
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );

  return (
    <>
      {renderPhotoModal()}
      <Breadcrumb
        items={[
          { text: "Games", link: "/games" },
          { text: game?.name || "", link: `/games/${game?.id}` },
          { text: "Follow", link: `/games/${game?.id}/follow` },
        ]}
      />
      <div className="row mb-3">
        {renderTabs()}
        <div className="col-12 col-md-6">
          <p className="float-end">
            Last update:{" "}
            {lastUpdate?.toLocaleString("sv-SE", {
              timeZone: "Europe/Stockholm",
            })}
          </p>
        </div>
      </div>
      <div className="row">
        <div className={showAnswers ? "col-8" : "col-12"}>
          {tab === Tab.Teams && renderTeams()}
          {tab === Tab.Map && renderMap()}
          {tab === Tab.Photos && renderPhotos()}
          {tab === Tab.SMS && renderSMS()}
          {tab === Tab.Variables && renderVariables()}
        </div>
        {showAnswers && (
          <div className="col-4">
            <br />
            <div className="card mt-3">
              <div className="card-header">
                {getDeviceDisplayNameShort(activeDeviceId)}
              </div>
              <div className="card-body">
                {activeItems.map((item) => (
                  <div key={item.id.toString()}>
                    <div className="alert alert-primary p-2 mb-1">
                      <p className="mb-0">
                        <small>
                          <i className={item.icon}></i> {item.id} - {item.text}
                        </small>
                      </p>
                    </div>
                    <div className={`alert p-2 alert-${item.className}`}>
                      <p className="mb-0" style={{ textAlign: "right" }}>
                        <small>{item.answer}</small>
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
